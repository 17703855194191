import { uuid } from "@/utils/string";
import Survey from "@/models/Survey";
import Rule from "@/models/Rule";
import i18n from "@/plugins/i18n";
import { Ref, Referable } from "@/typings/global-types";

export enum RuleGroupType {
  And = 'all',
  Or = 'any',
  One = 'one', // must match one of many - TODO: in QApp, but not in design
  Only = 'only', // only one rule; not a group
}

export default class RuleGroup implements Referable {
  static readonly DEFAULT_RULE_GROUP_TYPE = RuleGroupType.Or;

  rules: (Rule | RuleGroup)[];

  constructor(
    survey: Survey,
    public ref: Ref = null,
    public type: RuleGroupType = RuleGroupType.Only,
    rules: (Rule | RuleGroup)[] = null,
  ) {
    this.ref = ref ?? uuid();
    this.type = this.type ?? RuleGroupType.Only;

    this.rules = [];
    this.addRules(rules ?? [], survey);
  }

  addRule(rule: Rule | RuleGroup, index: number = null, validateGroupType = true) {
    if (index !== null && this.rules[index]) {
      this.rules.splice(index, 0, rule);
    } else {
      this.rules.push(rule);
    }

    if (validateGroupType) {
      this.updateRuleGroupType();
      if (rule instanceof RuleGroup) {
        rule.updateRuleGroupType();
      }
    }
  }

  isObjectRuleGroup(ruleGroupObject: any): boolean {
    const objProps = Object.keys(ruleGroupObject);
    const classInstance = new RuleGroup(new Survey());
    const classProperties = Object.keys(classInstance);

    return objProps.length === classProperties.length && objProps.every(prop => classProperties.includes(prop));
  }

  addRules(rules: (Rule | RuleGroup)[], survey: Survey) {
    if (!rules.length) {
      this.rules = [new Rule(survey)];
    } else {
      rules.forEach(rule => {
        if (rule instanceof RuleGroup || this.isObjectRuleGroup(rule)) {
          this.addRule(new RuleGroup(
            survey,
            (rule as RuleGroup).ref ?? null,
            (rule as RuleGroup).type ?? null,
            (rule as RuleGroup).rules ?? null
          ));
        } else {
          this.addRule(new Rule(
            survey,
            rule.ref ?? null,
            rule.node ?? null,
            rule.value ?? null,
            rule.operator ?? null
          ), null, false)
        }
      })
    }

    this.updateRuleGroupType();
  }

  deleteRule(rule: Rule | RuleGroup, survey: Survey, minOneRule = true) {
    survey.resetSurveyErrors(rule.ref);

    if (minOneRule && this.rules.length === 1) {
      if (rule instanceof RuleGroup) {
        rule = this.convertGridGroupToRule(rule, 0, survey);
      }
      (rule as Rule).reset();
    } else {
      this.rules.splice(this.getRuleIndex(rule), 1);
    }
    this.updateRuleGroupType();
  }

  getRuleIndex(rule: Rule | RuleGroup): number {
    return this.rules.findIndex(el => el.ref === rule.ref);
  }

  updateRuleGroupType() {
    if (this.rules.length <= 1) {
      this.type = RuleGroupType.Only;
    } else if (this.type === RuleGroupType.Only) {
      // update single rule to default grouped type
      this.type = RuleGroup.DEFAULT_RULE_GROUP_TYPE;
    }
  }

  convertGridGroupToRule(groupToConvert: RuleGroup, index: number, survey: Survey): Rule {
    this.deleteRule(groupToConvert, survey, false);
    this.addRule(groupToConvert.rules[0] as Rule, index);
    return this.rules[index] as Rule;
  }

  convertGridRuleToGroup(ruleToConvert: Rule, index: number, survey: Survey): RuleGroup {
    this.deleteRule(ruleToConvert, survey, false);
    const newGridGroup = new RuleGroup(survey, null, RuleGroup.DEFAULT_RULE_GROUP_TYPE, [ruleToConvert, new Rule(survey, null, ruleToConvert.node)]);
    this.addRule(newGridGroup, index);
    return this.rules[0] as RuleGroup;
  }

  getRefs(): Array<Ref> {
    const refs: Array<Ref> = []

    refs.push(this.ref)
    refs.push(...this.rules.map((rule: Referable) => rule.getRefs()).flat())

    return refs
  }
}

export interface RuleGroupTypeText {
  ref: string;
  label: string;
}

export const RULE_GROUP_TYPES_TEXT: RuleGroupTypeText[] = [
  { ref: RuleGroupType.And, label: i18n.global.t('question.logic.routing.and') },
  { ref: RuleGroupType.Or, label: i18n.global.t('question.logic.routing.or') }
]
