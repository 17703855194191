import {createI18n} from "vue-i18n";
import messages from "@/lang";

const i18n = createI18n({
  legacy: false,
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages // set locale messages
})

export default i18n
