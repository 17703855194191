export default {
  answer: {
    answer: 'Answer',
    answerOptions: 'Answer Options',
    sliderOptions: 'Slider Options',
    option: {
      title: 'Options',
      specify: 'Open End',
      exclusive: 'Exclusive',
      layoutType: 'Layout type',
      default: 'Default',
      numericOpen: 'Numeric Open',
      rangeMin: 'Range Min',
      rangeMax: 'Range Max',
      includeNumeric: 'Include numeric input box',
      setTotal: 'Set Total',
      leftSlideHeading: 'Left Slider Heading',
      rightSlideHeading: 'Right Slider Heading',
      min: 'Min',
      max: 'Max',
      steps: 'Steps',
      decimals: 'Allow Decimals',
      totalValue: 'Total to Allocate',
      numRanks: 'Number of ranks'
    },
    authoredOrder: 'Authored Order',
    randomOrder: 'Random Order',
    placeholder: 'Text field for users',
    routing: 'Routing',
    validation: {
      validNumber: 'Enter a valid number'
    },
  },
}
