import TranslationData from "./Data";
import Locale from './Locale';
import { UnknownTargetLocaleError } from "./errors";

export interface TranslationParserConstructor {
  canParse: (file: File) => boolean;
  new (): TranslationParser;
}

export interface TranslationParser {
  parse: (file: File) => Promise<TranslationData>|TranslationData;
  readonly fileType: string;
}


export default abstract class TranslationParserAbstract implements TranslationParser {
  abstract readonly fileType: string;

  abstract parse(file: File): Promise<TranslationData>|TranslationData;

  protected getLocale(locale: string): Locale {
    try {
      return new Locale(locale);
    } catch (e) {
      throw new UnknownTargetLocaleError(locale);
    }
  }
}
