// Translation strings
import en from '@/lang/en';
import {isDevEnv} from "@/constants/config";
import {customClone} from "@/utils/json";

const locales = {
  en,
  es: {}
};

if (isDevEnv()) {
  locales.es = customClone(en, 'Test: ');
}

export default locales;
