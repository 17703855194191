import Element from "@/models/Element";
import Node from "@/models/Node";
import type { VDataTable } from "vuetify/lib/components/index.d.mts"
import Page from "@/models/Page";
import RuleSet from "@/models/RuleSet";

enum Injections {
  Emitter = 'emitter'
}

enum Rails {
  QuestionListRail = 'QuestionList'
}

enum MainService {
  GetEntityList = 'GetEntityList'
}

type ActiveEditElement = Element | Node;
type ActiveEditElementType = typeof Page | typeof RuleSet | typeof Node;
const ACTIVE_EDIT_ELEMENT_TYPES = [Page, Node, RuleSet];

const ACTIVE_EDIT_ELEMENT_TYPE_MAP: Map<ActiveEditElementType, string> = new Map<ActiveEditElementType, string>([
  [Page, 'page'],
  [Node, 'node'],
  [RuleSet, 'ruleset'],
]);

type DataTableHeader = VDataTable['$props']['headers']

type Ref = string
interface Referable {
  ref: Ref,
  getRefs(): Array<Ref>,
}

export {
  Rails,
  Injections,
  ActiveEditElement,
  ActiveEditElementType,
  ACTIVE_EDIT_ELEMENT_TYPES,
  ACTIVE_EDIT_ELEMENT_TYPE_MAP,
  MainService,
  DataTableHeader,
  Referable,
  Ref,
}
