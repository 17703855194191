export default {
  suggestedAnswers: {
    agreement: {
      label: 'Agree & Disagree',
      options: {
        1: 'Strongly agree',
        2: 'Agree',
        3: 'Somewhat agree',
        4: 'Neither agree nor disagree',
        5: 'Somewhat disagree',
        6: 'Disagree',
        7: 'Strongly disagree'
      }
    },
    satisfaction: {
      label: 'Satisfied & Dissatisfied',
      options: {
        1: 'Extremely satisfied',
        2: 'Moderately satisfied',
        3: 'Slightly satisfied',
        4: 'Neither satisfied nor dissatisfied',
        5: 'Slightly dissatisfied',
        6: 'Moderately dissatisfied',
        7: 'Extremely dissatisfied'
      },
    },
    gender: {
      label: 'Gender',
      options: {
        1: 'Male',
        2: 'Female',
        3: 'Prefer not to say',
        4: 'Non-binary'
      },
    },
    familiarity: {
      label: 'Familiarity',
      options: {
        1: 'Extremely familiar',
        2: 'Very familiar',
        3: 'Moderately familiar',
        4: 'Slightly familiar',
        5: 'Not familiar at all'
      },
    },
    accurateScale: {
      label: 'Accurate Scale',
      options: {
        1: 'Extremely accurate',
        2: 'Very accurate',
        3: 'Moderately accurate',
        4: 'Slightly accurate',
        5: 'Not accurate at all'
      },
    },
    howWell: {
      label: 'How well?',
      options: {
        1: 'Extremely well',
        2: 'Very well',
        3: 'Moderately well',
        4: 'Slightly well',
        5: 'Not very well at all'
      },
    },
    interesting: {
      label: 'Interesting',
      options: {
        1: 'Extremely interesting',
        2: 'Very interesting',
        3: 'Moderately interesting',
        4: 'Slightly interesting',
        5: 'Not interesting at all'
      },
    },
    adequacy: {
      label: 'Adequacy',
      options: {
        1: 'Extremely adequate',
        2: 'Moderately adequate',
        3: 'Somewhat adequate',
        4: 'Neither adequate nor inadequate',
        5: 'Slightly inadequate',
        6: 'Moderately inadequate',
        7: 'Extremely inadequate'
      },
    },
    competency: {
      label: 'Competency',
      options: {
        1: 'Extremely competent',
        2: 'Moderately competent',
        3: 'Somewhat competent',
        4: 'Neither competent nor incompetent',
        5: 'Slightly incompetent',
        6: 'Moderately incompetent',
        7: 'Extremely incompetent'
      },
    },
    positiveNegative: {
      label: 'Positive-Negative',
      options: {
        1: 'Extremely positive',
        2: 'Moderately positive',
        3: 'Somewhat positive',
        4: 'Neither positive nor negative',
        5: 'Slightly negative',
        6: 'Moderately negative',
        7: 'Extremely negative'
      },
    },
    comfortability: {
      label: 'Comfortability',
      options: {
        1: 'Extremely comfortable',
        2: 'Moderately comfortable',
        3: 'Somewhat comfortable',
        4: 'Neither comfortable nor uncomfortable',
        5: 'Slightly uncomfortable',
        6: 'Moderately uncomfortable',
        7: 'Extremely uncomfortable'
      },
    },
    probability: {
      label: 'Probability',
      options: {
        1: 'Definitely will',
        2: 'Probably will',
        3: 'Might or might not',
        4: 'Probably will not',
        5: 'Definitely will not'
      },
    },
    likeability: {
      label: 'Likeability',
      options: {
        1: 'Always',
        2: 'Most of the time',
        3: 'About half of the time',
        4: 'Sometimes',
        5: 'Never'
      },
    },
    expectations: {
      label: 'Expectations',
      options: {
        1: 'Far exceeds expectation',
        2: 'Exceeds expectation',
        3: 'As expected',
        4: 'Short of expectation',
        5: 'Far short of expectations'
      },
    },
    quantity: {
      label: 'Quantity',
      options: {
        1: 'Much more',
        2: 'Moderately more',
        3: 'Slightly more',
        4: 'About the same',
        5: 'Slightly less',
        6: 'Moderately less',
        7: 'Much less'
      },
    },
    intervals: {
      label: 'Intervals',
      options: {
        1: 'Daily',
        2: '4-6 times a week',
        3: '2-3 times a week',
        4: 'Once a week',
        5: 'Never'
      },
    },
    importance: {
      label: 'Importance',
      options: {
        1: 'Extremely important',
        2: 'Very important',
        3: 'Moderately important',
        4: 'Slightly important',
        5: 'Not at all important'
      },
    },
    usefulness: {
      label: 'Usefulness',
      options: {
        1: 'Extremely useful',
        2: 'Moderately useful',
        3: 'Slightly useful',
        4: 'Neither useful or useless',
        5: 'Slightly useless',
        6: 'Moderately useless',
        7: 'Extremely useless'
      },
    },
    likely: {
      label: 'Likely',
      options: {
        1: 'Extremely likely',
        2: 'Moderately likely',
        3: 'Slightly likely',
        4: 'Neither likely or unlikely',
        5: 'Slightly unlikely',
        6: 'Moderately unlikely',
        7: 'Extremely unlikely'
      },
    },
    usStates: {
      label: 'US States',
      options: {
        1: 'Alabama',
        2: 'Alaska',
        3: 'Arizona',
        4: 'Arkansas',
        5: 'California',
        6: 'Colorado',
        7: 'Connecticut',
        8: 'Delaware',
        9: 'District of Columbia',
        10: 'Florida',
        11: 'Georgia',
        12: 'Hawaii',
        13: 'Idaho',
        14: 'Illinois',
        15: 'Indiana',
        16: 'Iowa',
        17: 'Kansas',
        18: 'Kentucky',
        19: 'Louisiana',
        20: 'Maine',
        21: 'Maryland',
        22: 'Massachusetts',
        23: 'Michigan',
        24: 'Minnesota',
        25: 'Mississippi',
        26: 'Missouri',
        27: 'Montana',
        28: 'Nebraska',
        29: 'Nevada',
        30: 'New Hampshire',
        31: 'New Jersey',
        32: 'New Mexico',
        33: 'New York',
        34: 'North Carolina',
        35: 'North Dakota',
        36: 'Ohio',
        37: 'Oklahoma',
        38: 'Oregon',
        39: 'Pennsylvania',
        40: 'Rhode Island',
        41: 'South Carolina',
        42: 'South Dakota',
        43: 'Tennessee',
        44: 'Texas',
        45: 'Utah',
        46: 'Vermont',
        47: 'Virginia',
        48: 'Washington',
        49: 'West Virginia',
        50: 'Wisconsin',
        51: 'Wyoming',
        52: 'Outside of the US',
        53: 'Prefer not to say'
      }
    }
  }
}
