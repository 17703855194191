import { uuid } from "@/utils/string";
import Survey from "@/models/Survey";
import {
  NodeTypeId,
  QUESTION_NUMERIC_TYPES,
  QUESTION_2D_TYPES,
  QUESTION_CHOICE_TYPES,
  QUESTION_MULTI_SELECTION_TYPES,
  QUESTION_SINGLE_SELECTION_TYPES, QUESTION_TYPES_SUPPORTED_ROUTING
} from "@/models/Node";
import QuestionType1d, {OptionSetProperty} from "@/models/nodeTypes/QuestionType1d";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import Node from "@/models/Node";
import i18n from "@/plugins/i18n";
import { Ref, Referable } from "@/typings/global-types";

export default class Rule implements Referable {
  constructor(
    survey: Survey,
    public ref: Ref = null,
    public node: string = null,
    public value: RuleValue = null,
    public operator: RuleOperator = null,
    validate: boolean = true,
  ) {
    this.ref = this.ref ?? uuid();
    if (validate) {
      this.validate(survey, value);
    }
  }

  validate(survey: Survey, value: RuleValue = null) {
    this.value = null;
    const node = this.node !== null ? this.getRuleNode(survey) : null;
    if (node) {
      if (!QUESTION_TYPES_SUPPORTED_ROUTING.includes(node.type)) {
        // unsupported node, reset answers, but keep node ref for communicating unsupported type error
        this.reset(true, survey);
      } else if (QUESTION_CHOICE_TYPES.includes(node.type)) {
        this.validateSetValue('set1', node as QuestionType1d, value, survey);

        if (QUESTION_2D_TYPES.includes(node.type)) {
          this.validateSetValue('set2', node as QuestionType2d, value, survey);
        }
      } else if (QUESTION_NUMERIC_TYPES.includes(node.type)) {
        this.value = !isNaN(Number(value)) ? value : null;
      } else {
        // text questions
        this.value = typeof value === 'string' || typeof value === 'number' ? value : null;
      }
    } else {
      // node was deleted, reset the rule
      this.reset();
    }
    this.validateOperatorValue(node?.type ?? null);
  }

  validateOperatorValue(nodeType: NodeTypeId = null) {
    if (nodeType === null) {
      this.operator = null;
      return;
    }

    switch (true) {
      case QUESTION_SINGLE_SELECTION_TYPES.includes(nodeType):
        this.resetInvalidOperator(nodeType, RULE_SINGLE_SELECTION_OPERATORS);
        return;
      case QUESTION_MULTI_SELECTION_TYPES.includes(nodeType):
        this.resetInvalidOperator(nodeType, RULE_MULTI_SELECTION_OPERATORS);
        return;
      case QUESTION_NUMERIC_TYPES.includes(nodeType):
        this.resetInvalidOperator(nodeType, RULE_NUMERIC_OPERATORS);
        return;
      default:
        this.resetInvalidOperator(nodeType, RULE_TEXT_OPERATORS);
    }
  }

  resetInvalidOperator(nodeType, validOperators) {
    if (!validOperators.includes(this.operator)) {
      this.operator = null;
    }
  }

  validateSetValue(setNum: OptionSetProperty, node: Node, value: RuleValue, survey: Survey) {
    let newValue: RuleValue | {} = this.value;
    (newValue ??= {})[setNum] ??= [];

    if (value && value[setNum]) {
      const set = survey.getOptionSetByRef(node[setNum]);
      if (set) {
        if (Array.isArray(value[setNum])) {
          value[setNum].forEach(optionRef => {
            const option = set.getOptionByRef(optionRef);
            if (option) {
              newValue[setNum].push(optionRef);
            }
          })
        } else {
          newValue[setNum] = set.getOptionByRef(value[setNum])?.ref ?? null;
        }
      }
    }

    this.value = newValue as RuleValue;
  }

  getRuleNode(survey): Node | null {
    return survey.getNodeByRef(this.node);
  }

  reset(excludeNode = false, survey: Survey = null) {
    if (!excludeNode) {
      this.node = null;
      this.value = null;
    } else if (survey) {
      const node = this.node !== null ? this.getRuleNode(survey) : null;
      if (node instanceof QuestionType1d) {
        this.validateSetValue('set1', node, null, survey);

        if (node instanceof QuestionType2d) {
          this.validateSetValue('set2', node, null, survey);
        }
      } else {
        this.value = null;
      }
    }

    this.operator = null;
  }

  isRuleComplete(survey: Survey): boolean {
    let isComplete = this.isRuleNodeComplete() && this.isRuleOperatorComplete() && this.isRuleValueComplete();

    // check sets
    const node = this.getRuleNode(survey);
    if (isComplete && node instanceof QuestionType1d) {
      isComplete = this.isRuleValueSetComplete('set1', node);

      if (isComplete && node instanceof QuestionType2d) {
        isComplete = this.isRuleValueSetComplete('set2', node);
      }
    }

    return isComplete;
  }

  isRuleNodeComplete(): boolean {
    return this.node !== null && this.node !== '' && this.value !== undefined;
  }

  isRuleOperatorComplete(): boolean {
    return !!this.operator;
  }

  isRuleValueComplete(): boolean {
    return this.value !== null && this.value !== '' && this.value !== undefined;
  }

  isRuleValueSetComplete(set: OptionSetProperty, node: Node): boolean | null {
    if (node instanceof QuestionType1d) {
      return !!(this.value as RuleChoiceValue)?.[set]?.length;
    }
    return null;
  }

  getOperatorTextByType(survey: Survey) {
    const type = survey.getNodeByRef(this.node)?.type;

    switch (true) {
      case QUESTION_SINGLE_SELECTION_TYPES.includes(type):
        return RULE_OPERATORS_TEXT.filter(operator => RULE_SINGLE_SELECTION_OPERATORS.includes(operator.id));
      case QUESTION_MULTI_SELECTION_TYPES.includes(type):
        return RULE_OPERATORS_TEXT.filter(operator => RULE_MULTI_SELECTION_OPERATORS.includes(operator.id));
      case QUESTION_NUMERIC_TYPES.includes(type):
        return RULE_OPERATORS_TEXT.filter(operator => RULE_NUMERIC_OPERATORS.includes(operator.id));
      default:
        return RULE_OPERATORS_TEXT.filter(operator => RULE_TEXT_OPERATORS.includes(operator.id));
    }
  }

  getRefs(): Array<Ref> {
    return [this.ref]
  }
}

export type RuleValue = number | string | RuleChoiceValue;

export interface RuleChoiceValue {
  set1: string | string[],
  set2?: string[],
}

export enum RuleOperator {
  Equals = 'equals',
  EqualsAny = 'equals-any',
  EqualsAll = 'equals-all',
  NotEqual = 'not-equal',
  NotEqualsAny = 'not-equals-any',
  NotEqualsAll = 'not-equals-all',
  OnlyEquals = 'only-equals',
  GreaterThan = 'greater-than',
  GreaterThanOrEqual = 'greater-than-eq',
  LessThan = 'less-than',
  LessThanOrEqual = 'less-than-eq',
  ContainsCaseInsensitive = 'icontains',
  NotContainsCaseInsensitive = 'not-icontains',
}

export const RULE_BASE_OPERATORS = [
  RuleOperator.Equals,
  RuleOperator.NotEqual,
];

export const RULE_SINGLE_SELECTION_OPERATORS = [
  RuleOperator.EqualsAny,
  RuleOperator.NotEqualsAny,
];

export const RULE_MULTI_SELECTION_OPERATORS = [
  RuleOperator.EqualsAny,
  RuleOperator.EqualsAll,
  RuleOperator.NotEqualsAny,
  RuleOperator.NotEqualsAll,
  // RuleOperator.OnlyEquals, // hiding for now until supported in QApp
];

export const RULE_NUMERIC_OPERATORS = [
  ...RULE_BASE_OPERATORS,
  RuleOperator.GreaterThan,
  RuleOperator.GreaterThanOrEqual,
  RuleOperator.LessThan,
  RuleOperator.LessThanOrEqual,
];

export const RULE_TEXT_OPERATORS = [
  ...RULE_BASE_OPERATORS,
  RuleOperator.ContainsCaseInsensitive,
  RuleOperator.NotContainsCaseInsensitive,
]

export interface RuleOperatorText {
  id: RuleOperator;
  title: string;
  symbol: string | null;
  conditional?: string;
}

export const RULE_OPERATORS_TEXT: RuleOperatorText[] = [
  {
    id: RuleOperator.Equals, title: i18n.global.t('question.logic.routing.operator.equals'),
    symbol: '='
  },
  {
    id: RuleOperator.NotEqual, title: i18n.global.t('question.logic.routing.operator.doesNotEqual'),
    symbol: '!=' },
  {
    id: RuleOperator.EqualsAny, title: i18n.global.t('question.logic.routing.operator.equalsAny'),
    symbol: '=',
    conditional: i18n.global.t('question.logic.routing.or')
  },
  {
    id: RuleOperator.EqualsAll, title: i18n.global.t('question.logic.routing.operator.equalsAll'),
    symbol: '=',
    conditional: i18n.global.t('question.logic.routing.and')
  },
  {
    id: RuleOperator.NotEqualsAny, title: i18n.global.t('question.logic.routing.operator.doesNotEqualAny'),
    symbol: '!=',
    conditional: i18n.global.t('question.logic.routing.or')
  },
  {
    id: RuleOperator.NotEqualsAll, title: i18n.global.t('question.logic.routing.operator.doesNotEqualAll'),
    symbol: '!=',
    conditional: i18n.global.t('question.logic.routing.all')
  },
  {
    id: RuleOperator.OnlyEquals, title: i18n.global.t('question.logic.routing.operator.onlyEquals'),
    symbol: `${i18n.global.t('question.logic.routing.operator.only')} =`,
    conditional: i18n.global.t('question.logic.routing.all')
  },
  {
    id: RuleOperator.GreaterThan, title: i18n.global.t('question.logic.routing.operator.greaterThan'),
    symbol: '>'
  },
  {
    id: RuleOperator.GreaterThanOrEqual, title: i18n.global.t('question.logic.routing.operator.greaterThanEqual'),
    symbol: '>='
  },
  {
    id: RuleOperator.LessThan, title: i18n.global.t('question.logic.routing.operator.lessThan'),
    symbol: '<'
  },
  {
    id: RuleOperator.LessThanOrEqual, title: i18n.global.t('question.logic.routing.operator.lessThanEqual'),
    symbol: '<='
  },
  {
    id: RuleOperator.ContainsCaseInsensitive, title: i18n.global.t('question.logic.routing.operator.containsInsensitive'),
    symbol: i18n.global.t('question.logic.routing.operator.containsInsensitive')
  },
  {
    id: RuleOperator.NotContainsCaseInsensitive, title: i18n.global.t('question.logic.routing.operator.doesNotContainInsensitive'),
    symbol: "!" + i18n.global.t('question.logic.routing.operator.containsInsensitive')
  },
]
