import { NodeTypeId } from "@/models/Node";

export const QUESTION_TOOLBAR = [
  "undo",
  "redo",
  "|",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "|",
  "blockQuote",
  "link",
  "bulletedList",
  "numberedList",
  "|",
  "fontColor",
  "sourceEditing",
  "horizontalLine",
  "|",
  "image",
  "media"
];
export const ANSWER_TOOLBAR = [
  "undo",
  "redo",
  "|",
  "bold",
  "italic",
  "underline",
  "|",
  "link",
  "|",
  "fontColor",
  "sourceEditing"
];

//TODO: "INVALID POSITION", etc. is no longer prepended, but leaving for now for backwards compatability for any existing invalid surveys,
// we can likely remove after some time.
export const QUESTION_CODE_REGEX = /{{(?:INVALID POSITION: |INVALID QUESTION: |UNSUPPORTED QUESTION: )?Q([^}]*)}}/g;
export const QUESTION_CODE_SPECIFY_REGEX = /{{(?:INVALID POSITION: |INVALID QUESTION: |UNSUPPORTED QUESTION: )?Q([^}]*(?: open end response)?)}}/g;
export const QUESTION_CODE_WITH_HTML_TAGS_REGEX = /{(<[^{}]+>|<\/[^{}]+>)*{(<[^{}]+>|<\/[^{}]+>)*(Q(<[^{}]+>|<\/[^{}]+>)*\d+)(<[^{}]+>|<\/[^{}]+>)*}(<[^{}]+>|<\/[^{}]+>)*}/g;
export const HTML_TAG_REGEX = /(<[^<>]+>|<\/[^<>]+>)/g;
export enum QuestionTabs {
  Edit = 'edit',
  AddQuota = 'add-quota'
}

export interface QuestionRouting {
  ref: string;
  code: string;
  label: string;
  type?: NodeTypeId
  goToRef?: string;
  disabled?: boolean;
}

export interface QuestionQuotaBalance {
  id: string;
  value: string;
  title: string;
}

export enum DeleteElementType {
  Node = 'node',
  RuleSet = 'rule-set',
  Option = 'option',
  OptionSet = 'option-set',
  Page = 'page',
}

export enum DeleteDialogFeatureType {
  Condition = 'Condition',
  GoTo = 'GoTo',
  ElseGoTo = 'ElseGoTo',
  MaskingChild = 'MaskingChild',
  MaskingParent = 'MaskingParent',
  PipingChild = 'PipingChild',
  PipingParent = 'PipingParent',
}

export enum DeleteDialogAction {
  Delete = 'delete',
  ChangeType = 'change-type',
  Move = 'move',
}
