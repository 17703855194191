export default class CustomError extends Error {
  constructor() {
    super();
    this.name = this.constructor.name;
  }

  get translateOptions(): Record<string, string> {
    return {};
  }
}
