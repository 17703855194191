/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import i18n from './i18n'
import router from '@/router'
import pinia from '@/store'
import '@vuepic/vue-datepicker/dist/main.css'
import './vee-validate'
import * as directives from './directives';
import { AuthUserRolePlugin } from "@maru/vue-auth-router";

// Types
import type { App } from 'vue'
import {emitter} from "@/plugins/emitter";
import {Injections} from "@/typings/global-types";

export const registerPlugins = (app: App): void => {
  app
    .use(i18n)
    .use(router)
    .use(pinia)
    .use(AuthUserRolePlugin);

  app.config.globalProperties.emitter = emitter;
  app.provide(Injections.Emitter, emitter);
}

export function registerDirectives(app: App) {
  for (const name in directives) {
    app.directive(name, directives[name]);
  }
}


export { registerComponents } from "@maru/hub-vuetify-components";
