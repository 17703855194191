import Node, {NodeTypeId, BaseNodeParams, NodeOrdinalities, NodeTranslations} from "@/models/Node";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeText<
  TQuestionTypeTextOrdinalities extends QuestionTypeTextOrdinalities = QuestionTypeTextOrdinalities
> extends Node<TQuestionTypeTextOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-message-reply-text-outline';
  static readonly NODE_TITLE: string = 'question.type.text';
  static readonly DEFAULT_ORDINALITIES: QuestionTypeTextOrdinalities = {};
  static readonly NODE_REQUIRE: boolean = true;

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionTypeText.DEFAULT_ORDINALITIES) as TQuestionTypeTextOrdinalities,
    i18n: NodeTranslations = {}
  ) {
    super(baseNodeParams, ordinalities, i18n);

    this.type = NodeTypeId.Text;
  }
}

export interface QuestionTypeTextOrdinalities extends NodeOrdinalities {}

