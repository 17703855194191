export const customClone = (json: Object, prefix: string) => {
  const newObject = Object.assign({}, json);

  for (const key in newObject) {
    if (typeof newObject[key] === 'string') {
      newObject[key] = prefix + newObject[key];
    } else if (typeof newObject[key] === 'object') {
      newObject[key] = customClone(newObject[key], prefix);
    }
  }
  return newObject;
}
