import QuestionType1d, {
  LayoutAnswerColumns,
  OptionSetParams,
  QuestionType1dOrdinalities,
} from "./QuestionType1d";
import {BaseNodeParams, NodeOrdinalitiesTypes, NodeTranslations, NodeTypeId} from "@/models/Node";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeMultiSelect<
  TQuestionTypeMultiSelectOrdinalities extends QuestionTypeMultiSelectOrdinalities = QuestionTypeMultiSelectOrdinalities
> extends QuestionType1d<TQuestionTypeMultiSelectOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-checkbox-outline';
  static readonly NODE_TITLE: string = 'question.type.multiSelect';
  static readonly DEFAULT_ORDINALITIES: QuestionTypeMultiSelectOrdinalities = { layoutAnswerColumns: LayoutAnswerColumns.One };
  static readonly ORDINALITY_TYPES: NodeOrdinalitiesTypes = { layoutAnswerColumns: [LayoutAnswerColumns, 'null'] };
  static readonly NODE_SET1_SUPPORTS_SPECIFY: boolean = true;
  static readonly NODE_SET1_SUPPORTS_EXCLUSIVE: boolean = true;

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionTypeMultiSelect.DEFAULT_ORDINALITIES) as TQuestionTypeMultiSelectOrdinalities,
    i18n: NodeTranslations = {},
    optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.MultiSelect;
  }
}

export interface QuestionTypeMultiSelectOrdinalities extends QuestionType1dOrdinalities {}
