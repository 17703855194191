export default {
  selectBuilt: {
    draft: 'Draft',
    startFromScratch: 'Start from scratch',
    buildWithAICreator: 'Build with AI Creator',
    startFromTemplate: 'Start from template',
    surveySummary: 'Survey summary',
    reuseSurvey: 'Re-Use Survey'
  }
}
