import Node, {BaseNodeParams, NodeOrdinalities, NodeTranslations} from "@/models/Node";
import {NodeTypeId} from "@/models/Node";
import {cloneDeep} from "@/utils/lodash";

export default class NodeTypeInfo<
  TNodeTypeInfoOrdinalities extends NodeTypeInfoOrdinalities = NodeTypeInfoOrdinalities
> extends Node<TNodeTypeInfoOrdinalities> {
  static readonly NODE_ICON: string = 'mdi-card-text-outline';
  static readonly NODE_TITLE: string = 'question.type.info';
  static readonly NODE_EDIT_COMPONENT: string = '';
  static readonly DEFAULT_ORDINALITIES: NodeTypeInfoOrdinalities = {};
  static readonly NODE_REQUIRE: boolean = false; //require doesn't have any effect currently on infoboxes

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(NodeTypeInfo.DEFAULT_ORDINALITIES) as TNodeTypeInfoOrdinalities,
    i18n: NodeTranslations = {}
  ) {
    super(baseNodeParams, ordinalities, i18n);

    this.type = NodeTypeId.Info;
  }
}

export interface NodeTypeInfoOrdinalities extends NodeOrdinalities {}
