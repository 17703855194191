import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import shellBridge, {ShellStoreState, useShellStore} from "@maru/shell-app";
import {Store} from "pinia";
import {useSurveyStore} from "@/store/surveyStore";
import {useQuestionsService} from "@/composables/api/questionsService";
import Survey from "@/models/Survey";
import i18n from "@/plugins/i18n";
import authUserRole, {AuthUserRoleFn} from "@maru/vue-auth-router";
import {useAppStore} from "@/store/app";
import {Locked} from "@/services/ApiService";

export const loadSurveyOnLoadRoute = async (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const shellStore: ShellStoreState & Store = useShellStore();
  const surveyStore = useSurveyStore();
  const questionsService = useQuestionsService();

  if (!shellStore.initialized) {
    await new Promise((resolve) => {
      shellStore.$subscribe((_mutation, state: ShellStoreState) => {
        if (state.initialized) { // only if it has a true value we stop the awaiting.
          resolve(state.initialized);
        }
      })
    })
  }

  if (!surveyStore.hasSurvey) {
    try {
      const retrievedSurvey = await questionsService.getSurvey();
      if (to.name !== 'Confirmation' && (!retrievedSurvey.savedAt || retrievedSurvey.updatedAt > retrievedSurvey.savedAt)) {
        surveyStore.markEdited();
      }

      if (retrievedSurvey.surveyData) {
        surveyStore.setSurvey(Survey.fromData(retrievedSurvey.surveyData));
        surveyStore.setNew(false);
        surveyStore.setPublished(!!retrievedSurvey.publishedAt)
      }
    } catch (error) {
      console.log("Error loading survey: : ", error);
      shellBridge.sendError(i18n.global.t('builder.errors.draftSurveys'));
    }
  }

  let isObserver = true;
  try {
    isObserver = await authUserRole('observer');
  } catch (e) {
    console.error(e);
  }

  if (to.name !== 'Home' && !isObserver && !surveyStore.isSessionStarted && !surveyStore.isPublished) {
    try {
      await questionsService.startSession()
      surveyStore.startSession();
    } catch (error) {
      if (error instanceof Locked) {
        surveyStore.lockSession(error.data.editingUserId);
      }
    }
  }

  const appStore = useAppStore();
  appStore.setDisabled(to.name !== 'Home' && surveyStore.isSessionLocked || isObserver);

  next();
}
