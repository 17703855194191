import {Ref} from "@/typings/global-types";
import i18n from "@/plugins/i18n";

const { t } = i18n.global;

export default class SurveyError {
  public message = '';

  constructor(
    public errorType: SurveyErrorType,
    public errorProperty: SurveyErrorProperty,
    public errorItemRef: Ref,
    public errorEditElementRef: Ref = null,
    public messagePipes: Record<string, string|number> = {}
  ) {
    const messageKey = `builder.errors.model.${errorType}.${errorProperty}`
    this.message = t(messageKey, messagePipes);

    this.errorEditElementRef = this.errorEditElementRef ?? this.errorItemRef;
  }
}

export enum SurveyErrorProperty {
  RuleNode = 'rule.node',
  RuleOperator = 'rule.operator',
  RuleValueSet1 = 'rule.valueSet1',
  RuleValueSet2 = 'rule.valueSet2',
  RuleValue = 'rule.value',
  RuleSetGoTo = 'ruleSet.goTo',
  RuleSetElseGoTo = 'ruleSet.elseGoTo',
  OptionMaskingSet1 = 'optionMasking.set1',
  OptionMaskingSet2 = 'optionMasking.set2',
  OptionPiping = 'option.piping',
  OptionText = 'option.text',
  NodePiping = 'node.piping',
  NodeText = 'node.text',
  OptionSetOptions = 'optionSet.options',
  PageNodes = 'page.nodes',
  NodeOrdinalitiesNumericSliderIntervals = 'node.ordinalities.numericSlider.intervals',
  NodeOrdinalitiesNumericRange = 'node.ordinalities.numeric.range',
  NodeOrdinalitiesNumericMin = 'node.ordinalities.numeric.min',
  NodeOrdinalitiesNumericMax = 'node.ordinalities.numeric.max',
  NodeOrdinalitiesNumericStep = 'node.ordinalities.numeric.step',
}

export const RULE_SET_RULE_VALUE_ERROR_PROPERTIES = [
  SurveyErrorProperty.RuleValueSet1,
  SurveyErrorProperty.RuleValueSet2,
  SurveyErrorProperty.RuleValue,
]

export const RULE_SET_ERROR_PROPERTIES = [
  SurveyErrorProperty.RuleNode,
  SurveyErrorProperty.RuleOperator,
  SurveyErrorProperty.RuleSetGoTo,
  SurveyErrorProperty.RuleSetElseGoTo,
  ...RULE_SET_RULE_VALUE_ERROR_PROPERTIES,
]
export const NODE_TEXT_ERROR_PROPERTIES = [
  SurveyErrorProperty.NodePiping,
  SurveyErrorProperty.NodeText
]

export const NODE_ORDINALITIES_NUMERIC_ERROR_PROPERTIES = [
  SurveyErrorProperty.NodeOrdinalitiesNumericSliderIntervals,
  SurveyErrorProperty.NodeOrdinalitiesNumericRange,
  SurveyErrorProperty.NodeOrdinalitiesNumericMin,
  SurveyErrorProperty.NodeOrdinalitiesNumericMax,
  SurveyErrorProperty.NodeOrdinalitiesNumericStep,
]

export const NODE_ORDINALITIES_ERROR_PROPERTIES = [
  ...NODE_ORDINALITIES_NUMERIC_ERROR_PROPERTIES,
]

export const OPTION_TEXT_ERROR_PROPERTIES = [
  SurveyErrorProperty.OptionPiping,
  SurveyErrorProperty.OptionText
]
export const PIPING_ERROR_PROPERTIES = [
  SurveyErrorProperty.NodePiping,
  SurveyErrorProperty.OptionPiping,
]

export const MASKING_ERROR_PROPERTIES = [
  SurveyErrorProperty.OptionMaskingSet1,
  SurveyErrorProperty.OptionMaskingSet2,
]

export enum SurveyErrorType {
  Empty = 'empty',
  Invalid = 'invalid',
  Position = 'position',
  NonExistant = 'nonExistant',
  Length = 'length',
}
