import {ApiService, BadRequest} from "@/services/ApiService";
import {AxiosRequestConfig} from "axios";
import webBridge, {ShellStoreState, useShellStore} from "@maru/shell-app";
import { useSurveyStore } from "@/store/surveyStore";
import Survey from "@/models/Survey";
import Asset from "@/models/Asset";
import i18n from "@/plugins/i18n";

export interface RetrievedSurvey {
  surveyData: Survey | null,
  updatedAt: number | null,
  savedAt: number | null,
  publishedAt: number | null,
}

enum AuthHeaderContentType {
  MultipartFormData  = 'multipart/form-data',
  ApplicationJson = 'application/json'
}

export const useQuestionsService = () => {
    const shellStore: ShellStoreState = useShellStore();
    const { t } = i18n.global;

    const api: ApiService = new ApiService(import.meta.env.VITE_GLUE_URL);

    const getAuthHeader = (type: string): AxiosRequestConfig => {
      const token = shellStore.projectToken;
      return {
        headers: {
          'X-Token': token,
          'Content-Type': type,
        },
      };
    }

    const getSurvey = async (): Promise<RetrievedSurvey> => {
      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.get(`api/main/v1/project/${shellStore.project.id}/retrieve-version`, config);
    }

    const postSurvey = () => {
      const surveyStore = useSurveyStore();

      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      try {
        return api.post(`api/main/v1/project/${shellStore.project.id}/save-version`, surveyStore.survey, config);
      } catch (err) {
        if (err instanceof BadRequest) {
          webBridge.sendError(t('builder.errors.generic'));
          throw err
        }
      }
    }

    const surveyPreviewLink = () => {
      const shellStore = useShellStore();

      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.get(`api/main/v1/project/${shellStore.project.id}/preview-link`, config);
    }

    const startSession = () => {
      const surveyStore = useSurveyStore();

      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.post(`api/main/v1/project/${shellStore.project.id}/start-session`, !surveyStore.isNew ? surveyStore.survey : null, config);
    }

    const saveSession = () => {
      const surveyStore = useSurveyStore();

      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.post(`api/main/v1/project/${shellStore.project.id}/save-session`, surveyStore.survey, config);
    }

    const deleteDraft = () => {
      const shellStore = useShellStore();
      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.post(`api/main/v1/project/${shellStore.project.id}/save-session`, null, config);
    };

    const publishSurvey = () => {
      const surveyStore = useSurveyStore();

      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.post(`api/main/v1/project/${shellStore.project.id}/publish`, {"survey": surveyStore.survey}, config);
    }

    const audienceSummary = () => {
      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.get(`api/se-proxy/v1/project/${shellStore.project.id}/audience-summary`, config);
    }

    const sendMedia = (file: FormData): Promise<{location: string, ref: string}> => {
      const config = getAuthHeader(AuthHeaderContentType.MultipartFormData);
      return api.post(`api/main/v1/project/${shellStore.project.id}/send-media`, file, config);
    }

    const getTemplates = () => {
      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.get(`api/ai-proxy/v1/project/${shellStore.project.id}/templates`, config);
    }

    const getRelatedSurveys = (): Promise<SurveyItem> => {
      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson);
      return api.get(`api/main/v1/project/${shellStore.project.id}/related-surveys`, config);
    }

    const copyAssets = (fromProjectId: string, assets: Array<Asset> = []): Promise<MapAssets[]> => {
      const refs = assets.map((asset: Asset) => asset.ref)

      const config = getAuthHeader(AuthHeaderContentType.ApplicationJson)
      return api.post(`api/main/v1/project/${shellStore.project.id}/copy-assets/${fromProjectId}`, { refs }, config)
    }

    return {
      getSurvey,
      postSurvey,
      surveyPreviewLink,
      startSession,
      saveSession,
      deleteDraft,
      publishSurvey,
      sendMedia,
      getTemplates,
      audienceSummary,
      getRelatedSurveys,
      copyAssets,
    }
}

export enum CintValidationError {
  Valid = 0,
  UnknownError = 1,
  NotSetUp = 2,
  FieldPeriodInvalid = 3,
  SampleSizeInvalid = 4,
  IncidenceRateInvalid  = 5,
  LOIInvalid = 6,
  AgeQuotaInvalid = 7,
  GenderQuotaInvalid = 8,
  NotFeasible = 9
}

export interface CintError {
  audienceId: number,
  audienceName: string,
  validationErrorType: CintValidationError,
}

export interface CintData {
  isSubmitted: boolean,
  validationErrors: Array<CintError>,
}

export interface AudienceInfo {
  audeinceId: number,
  audeinceName: string,
  audeinceUniqueId: string,
  isConfigured: boolean,
  sampleSize: number,
  status: number,
  feasibilityOverride: boolean,
}

export enum SurveyStatus {
  Planned = 'planned',
  Field = 'field',
  Finished = 'finished',
  Archived = 'archived',
}

export interface SurveyItem {
  id: string
  name: string
  status: SurveyStatus
  questionCount: number
  survey: Survey,
  createdBy: string,
  createdAt: Date,
  mine: boolean,
  description: string,
}

export interface MapAssets {
  sourceRef: string,
  asset: Asset,
}

export enum Errors {
  InvalidStructure = 'invalid_survey_structure',
}
