// Composables
import {createRouter, createWebHistory, RouteLocationNormalized, RouterOptions} from '@maru/vue-auth-router'
import mainWebBridge from "@maru/main-app";
import { DialogRoute } from "@maru/hub-vuetify-components"
import shellBridge from "@maru/shell-app";
import {loadSurveyOnLoadRoute} from "@/router/guards/loadSurvey";

const history = createWebHistory(import.meta.env.BASE_URL)
const mainPaths = '/projectslist/:paths*';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        beforeEnter: loadSurveyOnLoadRoute,
      },
      {
        path: '/builder',
        name: 'Builder',
        component: () => import('@/views/Builder.vue'),
        beforeEnter: loadSurveyOnLoadRoute,
      },
      {
        path: '/example',
        name: 'Example',
        component: () => import('@/views/Example.vue'),
      },
      {
        path: '/confirmation',
        name: 'Confirmation',
        component: () => import('@/views/Confirmation.vue'),
        beforeEnter: loadSurveyOnLoadRoute,
      }
    ],
  },
  {
    path: mainPaths,
    beforeEnter(to: RouteLocationNormalized) {
      shellBridge.route(to.fullPath);
    },
    component: () => null, // Required to register this route
  },
  DialogRoute.record, // Dialog rendering
]

const router = createRouter({ history, routes } as RouterOptions)

router.beforeEach((to, from) => {
  const isMainPath = to.matched.find(match => match.path === mainPaths);
  if (!isMainPath && to.fullPath !== from.fullPath && !DialogRoute.matches(to, window.location.origin)) {
    mainWebBridge.route(to.fullPath);
  }
})

router.onError((error, to) => {
  const browserMessage = [
    'Importing a module script failed.', // Safari
    'error loading dynamically imported module', // Firefox
    'Failed to fetch dynamically imported module' // Chromium, Edge, Opera
  ];
  if (browserMessage.some(message => error.message.includes(message))) {
    window.location.href = to.fullPath;
  }
})

export default router
