import {BaseNodeParams, NodeTranslations, NodeTypeId} from "@/models/Node";
import QuestionType2d, {QuestionType2dOrdinalities} from "@/models/nodeTypes/QuestionType2d";
import {OptionSetParams} from "@/models/nodeTypes/QuestionType1d";
import Survey from "@/models/Survey";
import {cloneDeep} from "@/utils/lodash";

/**
 * Columns (set2) are set according to the number of ranks required with label set to a number.
 * Columns are hidden in the UI but used in routing and will allow setting/translating a label in future
 *  i.e. Most important, Least important vs. just setting as 1, 2 to start.
 */
export default class QuestionTypeRankOrder<
  TQuestionTypeRankOrderOrdinalities extends QuestionTypeRankOrderOrdinalities = QuestionTypeRankOrderOrdinalities
> extends QuestionType2d<TQuestionTypeRankOrderOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-format-list-numbered';
  static readonly NODE_TITLE: string = 'question.type.rankOrder';
  static readonly NODE_EDIT_COMPONENT: string = 'QuestionTypeRankOrderForm';
  static readonly NODE_SET2_HIDDEN: boolean = true;
  static readonly NODE_SET2_NUMERIC: boolean = true;

  constructor(
      baseNodeParams: BaseNodeParams,
      ordinalities = cloneDeep(QuestionType2d.DEFAULT_ORDINALITIES) as TQuestionTypeRankOrderOrdinalities,
      i18n: NodeTranslations = {},
      optionSetParams: OptionSetParams = {},
  ) {
    optionSetParams.addSet2PlaceholderOption = false; // don't want a placeholder added for ranks

    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.RankOrder;

    this.validateNumericColumns(baseNodeParams.survey);
  }

  getMaxNumColumnsAllowed(survey: Survey): number {
    // set 1 length, excluding placeholder
    return this.getSet1(survey).getOptionsLength(true) || 1;
  }
}

export interface QuestionTypeRankOrderOrdinalities extends QuestionType2dOrdinalities {}
