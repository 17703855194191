export default {
    alerts: {
        surveyIsPublished: 'Survey is published',
        surveyNotConfiguredError: "The survey can't be published without a configured audience. Please go to the audience targeting tab to set up all required fields.",
        surveyPublishingError: "The survey can't be published. Please contact to the support",
        errorGenerateAnswerOptions: 'Something went wrong while generating answer options',
        errorGenerateNextQuestion: 'Something went wrong while generating the question',
        errorGenerateIntro: 'Something went wrong while generating the intro',
        errorGenerateOutro: 'Something went wrong while generating the outro',
        errorDeleteDraft: {
          unexpected: 'An unexpected error has occurred while deleting the draft, please try again. If the error persists, please reach out to support for assistance',
          locked: 'Cannot delete survey draft, the survey is locked by {user}'
        },
        errorSaveSession: 'An unexpected error has occurred while saving the survey, please try again. If the error persists, please reach out to support for assistance',
        lockedSession: {
          title: 'Survey session is locked',
          description: 'This survey is locked by {user}',
        },
        unknownUser: 'unknown user {userId}',
    }
}
