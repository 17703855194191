import {BaseNodeParams, NodeTranslations, NodeTypeId} from "@/models/Node";
import QuestionType2d, {QuestionType2dOrdinalities} from "@/models/nodeTypes/QuestionType2d";
import {OptionSetParams} from "@/models/nodeTypes/QuestionType1d";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeMultiSelectGrid<
  TQuestionTypeMultiSelectGridOrdinalities extends QuestionTypeMultiSelectGridOrdinalities = QuestionTypeMultiSelectGridOrdinalities
> extends QuestionType2d<TQuestionTypeMultiSelectGridOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-grid';
  static readonly NODE_TITLE: string = 'question.type.multiSelectGrid';
  static readonly NODE_SET2_SUPPORTS_EXCLUSIVE: boolean = true;

  constructor(
      baseNodeParams: BaseNodeParams,
      ordinalities = cloneDeep(QuestionType2d.DEFAULT_ORDINALITIES) as TQuestionTypeMultiSelectGridOrdinalities,
      i18n: NodeTranslations = {},
      optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.MultiSelectGrid;
  }
}

export interface QuestionTypeMultiSelectGridOrdinalities extends QuestionType2dOrdinalities {}
