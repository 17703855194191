import { uuid } from "@/utils/string";
import Survey from "@/models/Survey";
import { Ref, Referable } from "@/typings/global-types";
import i18n from "@/plugins/i18n";
import {RuleSetSyntax} from "@/models/RuleSet";

export default abstract class Element implements Referable {
  static readonly DEFAULT_HIDE = false;

  type: ElementType;
  ref: Ref;
  code: number;
  name: string;
  hide: boolean;

  protected constructor(baseElementParams) {
    const {survey, ref, code, name, hide} = baseElementParams;

    this.ref = ref ?? uuid();
    this.code = code ?? null;
    this.name = name ?? '';
    this.hide = hide ?? Element.DEFAULT_HIDE;
  }

  getCleanTitle(survey: Survey = null): string | RuleSetSyntax {
    return this.getCodeLabel() + (this.name?.length ? ': ' + this.name : '');
  }

  getCodeLabel(initialKey: string = ''): string {
    return this.code !== null ? `${i18n.global.t(initialKey)}${this.code}` : '';
  }

  getRefs(): Array<Ref> {
    return [this.ref]
  }
}

export interface BaseElementParams {
  survey: Survey,
  ref?: Ref,
  code?: number,
  name?: string,
  hide?: boolean,
}

export enum ElementType {
  Page = 'page',
  RuleSet = 'rule-set',
}
