import RuleGroup, {RuleGroupType} from "@/models/RuleGroup";
import Element, {BaseElementParams, ElementType} from "@/models/Element";
import Survey from "@/models/Survey";
import Rule, {RULE_OPERATORS_TEXT, RuleValue} from "@/models/Rule";
import i18n from "@/plugins/i18n";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import QuestionTypeText from "@/models/nodeTypes/QuestionTypeText";
import QuestionType1d, {OptionSetProperty} from "@/models/nodeTypes/QuestionType1d";
import Page from "@/models/Page";
import Node from "@/models/Node";
import {stripHTML} from "@/utils/string";
import { Ref } from "@/typings/global-types";

export default class RuleSet extends Element {
  ruleGroup: RuleGroup;

  constructor(
    baseElementParams: BaseElementParams,
    public goToType: RuleSetGoToType = null,
    public goTo: RuleSetGoToStatus | string = null, // if goToType is a skip, value is a page ref
    public elseGoToType: RuleSetGoToType = null,
    public elseGoTo: RuleSetGoToStatus | string = null,
    ruleGroup: RuleGroup = null,
  ) {

    super(baseElementParams);
    this.type = ElementType.RuleSet;

    this.addRuleGroup(ruleGroup, baseElementParams.survey);
    this.updateGoToType();
    this.updateElseGoToType();
  }

  addRuleGroup(ruleGroup: RuleGroup, survey: Survey) {
      this.ruleGroup = !ruleGroup
        ? new RuleGroup(survey)
        : new RuleGroup(survey, ruleGroup.ref ?? null, ruleGroup.type ?? null, ruleGroup.rules ?? null);
  }

  updateGoToType() {
    this.goToType = Object.values(RuleSetGoToStatus).includes(this.goTo as RuleSetGoToStatus) ? RuleSetGoToType.Terminate : RuleSetGoToType.Skip;
  }

  updateElseGoToType() {
    this.elseGoToType = Object.values(RuleSetGoToStatus).includes(this.elseGoTo as RuleSetGoToStatus) ? RuleSetGoToType.Terminate : RuleSetGoToType.Skip;
  }

  getPreviousPageCodeLabel(survey: Survey): number | null {
    const pages = (survey.getPreviousElementsByType(this, ElementType.Page, false, false) as Page[])
      .filter(page => page.nodes.length);

    return (pages === null || !pages.length) ? null : pages[0].code;
  }

  getNextPageRef(survey: Survey): string | null {
    const pages = (survey.getNextElementsByType(this, ElementType.Page, false, false) as Page[])
      .filter(page => page.nodes.length);

    return (pages === null || !pages.length) ? null : pages[0]?.ref;
  }

  getCodeLabel(): string {
    return super.getCodeLabel('question.logic.routing.logicInitial');
  }

  getCleanTitle(survey: Survey = null): string | RuleSetSyntax {
    if (this.name?.length) {
      return super.getCleanTitle() as string;
    }

    if (survey) {
      return this.ruleGroup.rules.length
        ? this.generateRuleSetSyntax(survey) ?? this.getCodeLabel() + ": " + i18n.global.t('question.logic.routing.noRuleSet')
        : this.getCodeLabel() + ": " + i18n.global.t('question.logic.routing.noRuleSet');
    }

    return this.getCodeLabel();
  }

  generateRuleSetSyntax(survey: Survey): RuleSetSyntax {
    const ruleSetSyntax: RuleSetSyntax = {
      groupType: '',
      rules: [] as RulesSyntax[],
      goTo: '',
      elseGoTo: '',
    };

    this.ruleGroup.rules.forEach(rule => {
      if (rule instanceof Rule) {
        const syntax = this.generateRuleQuestionSyntax(rule, survey);
        if (syntax) {
          ruleSetSyntax.rules.push({groupType: null, rules: [syntax]});
        }
      } else if (rule instanceof RuleGroup) {
        const ruleGroup = {groupType: this.generateRuleGroupTypeSyntax((rule as RuleGroup).type), rules: []};
        (rule as RuleGroup).rules.forEach(row => {
          const syntax = this.generateRuleQuestionSyntax(row as Rule, survey);
          if (syntax) {
            ruleGroup.rules.push(syntax);
          }
        })
        if (ruleGroup.rules.length) {
          ruleSetSyntax.rules.push(ruleGroup);
        }
      }
    })

    if (!ruleSetSyntax.rules.length) {
      return null;
    }

    ruleSetSyntax.groupType = this.getCodeLabel() + ": " + this.generateRuleGroupTypeSyntax(this.ruleGroup.type);

    let goToStatusText;
    if (this.goToType === RuleSetGoToType.Skip) {
      const code = (survey.getElementByRef(this.goTo) as Page)?.code ?? null;
      goToStatusText = code !== null
        ? i18n.global.t('page.initial') + code
        : i18n.global.t('question.logic.routing.goTo.undefined');
    } else {
      goToStatusText = RULE_SET_GO_TO_STATUSES_TEXT.find(text => text.goToRef === this.goTo)?.label
       ?? i18n.global.t('question.logic.routing.goTo.undefined');
    }
    ruleSetSyntax.goTo = i18n.global.t('question.logic.routing.goTo.thenGoToSyntax') + " " + goToStatusText;

    let elseGoToStatusText;
    if (this.elseGoToType === RuleSetGoToType.Skip) {
      const code = (survey.getElementByRef(this.elseGoTo) as Page)?.code ?? null;
      elseGoToStatusText = code !== null
        ? i18n.global.t('page.initial') + code
        : i18n.global.t('question.logic.routing.goTo.undefined');
    } else {
      elseGoToStatusText = RULE_SET_GO_TO_STATUSES_TEXT.find(text => text.goToRef === this.elseGoTo)?.label
        ?? i18n.global.t('question.logic.routing.goTo.undefined');
    }
    ruleSetSyntax.elseGoTo = i18n.global.t('question.logic.routing.goTo.elseGoToSyntax') + " " + elseGoToStatusText;

    return ruleSetSyntax;
  }

  generateRuleQuestionSyntax(rule: Rule, survey: Survey): string | null {
    const node = rule.node ? survey.getNodeByRef(rule.node) : null;
    const operator = rule.operator;
    const value = rule.value;

    if (node && operator && value) {
      const nodeCode = i18n.global.t('question.questionInitial') + node.code;

      const optionText = node instanceof QuestionType1d
        ? this.generateRuleAnswerSyntax(value, 'set1', node, rule, survey)
        : node instanceof QuestionTypeText ? `"${value}"` : value;

      const columns = node instanceof QuestionType2d ? this.generateRuleAnswerSyntax(value, 'set2', node, rule, survey) : '';

      const symbol = RULE_OPERATORS_TEXT.find(text => text.id === rule.operator).symbol;

      const finalOptionText = stripHTML(columns
        ? ` - ${optionText} ${symbol} ${columns}`
        : `${symbol} ${optionText}`);

      return `${nodeCode} ${finalOptionText}`;
    }

    return null;
  }

  generateRuleAnswerSyntax(value: RuleValue, setNum: OptionSetProperty, node: Node, rule: Rule, survey: Survey): string {
    const optionsTextArray: string[] = [];
    if (value[setNum]) {
      const set = survey.getOptionSetByRef(node[setNum]);
      if (set) {
        const optionRefs = Array.isArray(value[setNum]) ? value[setNum] : [value[setNum]];
        optionRefs.forEach(optionRef => {
          const option = set.getOptionByRef(optionRef);
          if (option) {
            optionsTextArray.push(option.label);
          }
        })
      }
    }

    const optionsText = optionsTextArray.length > 1
      ? `${optionsTextArray.slice(0, -1).join(', ')}
           ${RULE_OPERATORS_TEXT.find(text => text.id === rule.operator).conditional.toUpperCase()}
           ${optionsTextArray[optionsTextArray.length - 1]}`
      : optionsTextArray[0] ?? i18n.global.t('question.logic.routing.answersUndefined');

    return optionsText;
  }

  generateRuleGroupTypeSyntax(groupType: RuleGroupType) {
    let groupTypeText = i18n.global.t('question.logic.routing.ifOnlyTrue');
    if (groupType === RuleGroupType.And) {
      groupTypeText = i18n.global.t('question.logic.routing.ifAllTrue')
    } else if (groupType === RuleGroupType.Or)  {
      groupTypeText = i18n.global.t('question.logic.routing.ifAnyTrue')
    }
    return groupTypeText;
  }

  getAllNodesInRuleSetRules(survey: Survey): Node[] {
    const getNodes = (rules: (Rule | RuleGroup)[]): Node[] => {
      return rules.flatMap(rule => {
        if (rule instanceof Rule) {
          return rule.getRuleNode(survey);
        } else if (rule instanceof RuleGroup) {
          // recursively check nested rules
          return getNodes(rule.rules);
        }
      }).filter(node => node !== null);
    };

    return getNodes(this.ruleGroup.rules);
  }

  getGoToPageInRuleSet(survey): Page | null {
    return this.goToType === RuleSetGoToType.Skip ? survey.getElementByRef(this.goTo) as Page : null;
  }

  getElseGoToPageInRuleSet(survey): Page | null {
    return this.elseGoToType === RuleSetGoToType.Skip ? survey.getElementByRef(this.elseGoTo) as Page : null;
  }
  getRefs(): Array<Ref> {
    const refs = super.getRefs()

    refs.push(...this.ruleGroup.getRefs())

    return refs
  }
}

export enum RuleSetGoToStatus {
  ScreenOut = 'screen-out',
  Complete = 'complete',
}

export const RULE_SET_GO_TO_STATUSES = [RuleSetGoToStatus.ScreenOut, RuleSetGoToStatus.Complete];

export interface RuleSetGoToStatusText {
  goToRef: string;
  label: string;
}

export const RULE_SET_GO_TO_STATUSES_TEXT: RuleSetGoToStatusText[] = [
  { goToRef: RuleSetGoToStatus.Complete, label: i18n.global.t('question.logic.routing.goTo.endSurvey') },
  { goToRef: RuleSetGoToStatus.ScreenOut, label: i18n.global.t('question.logic.routing.goTo.terminate') }
]

export enum RuleSetGoToType {
  Terminate = 'terminate',
  Skip = 'skip',
}

export type RuleSetGoToProperty = 'goTo' | 'elseGoTo';

export interface RuleSetSyntax {
  groupType: string,
  rules: RulesSyntax[],
  goTo: string,
  elseGoTo: string,
}

export interface RulesSyntax {
  groupType: string | null,
  rules: string[],
}

//TODO: create tests and delete below once design and functionality questions confirmed!
/* COMPLEX EXAMPLE w/ groups - still TBC if supporting groups initially, but should roughly future proof for it either way
//((Q1 Row Coke = Agree AND Q2 > 3) OR (Q3 = 1 OR Q3 = 2))) AND Q4 CONTAINS "Pepsi")
const ruleSet: RuleSet = {
  ref: '123',
  name: 'DQ if some grouped condition',
  goToType: 'terminate',
  goTo: 'screen-out',
  ruleGroup: {
    ref: 'Q1_Q2_Q3_Q4GroupRef',
    type: 'all',
    rules: [
      {
        ref: 'Q1_Q2_Q3_GroupRef',
        type: 'any',
        rules: [
          {
            ref: 'Q1_Q2GroupRef',
            type: 'all',
            rules: [
              {
                ref: 'Q1RuleRef',
                node: 'Q1', //node ref
                operator: 'equals',
                value: {set1: 'Coke', set2: 'Agree'}, //row option ref; column option ref
                //value: {set1: 'Coke', set2: ['Agree', 'Neutral']} //if go with multi dd for set2 and equals any of/all of operators
              },
              { ref: 'Q2RuleRef', node: 'Q2', operator: 'greater-than', value: 3 },
            ],
          },
          {
            type: 'any',
            ref: 'Q3GroupRef',
            rules: [
              { ref: 'Q3_1RuleRef', node: 'Q3', operator: 'equals', value: 1 },
              { ref: 'Q3_2RuleRef', node: 'Q3', operator: 'equals', value: 2 },
            ],
            //or if go with multi-select dd could use "equals any of"/"all of" operators
            // type: node,
            // rules: [
            //   { ref: 'Q3RuleRef', node: 'Q3', operator: 'equals-any', value: [1,2] },
            // ],
          },
        ],
      },
      {
        type: 'only',
        ref: 'Q4GroupRef', //wouldn't exist in QApp, but thinking easier for consistancy to follow same structure
        rules: [
          { ref: 'Q4RuleRef', node: 'Q4', operator: 'icontains', value: 'Pepsi' },
        ],
      },
    ],
  },
};


//SAME EXAMPLE WITH CORRESPONDING QAPP SYNTAX:
//((Q1 Row Coke = Agree AND Q2 > 3) OR (Q3 = 1 OR Q3 = 2))) AND Q4 CONTAINS "Pepsi")
const survey = {
    rules: [
      {
        ref: 'Q1RuleRef',
        name: 'Q1 Row Coke = Agree', //name is optional
        variableName: 'Q1', //QApp needs to save node value to variable and use that name
        variableMatch: '[Coke - Agree]', //row option ref - column option ref
        type: 'variable',
        operator: 'icontains',
      },{
        ref: 'Q2RuleRef',
        name: 'Q2 > 3',
        variableName: 'Q2',
        variableMatch: '3',
        type: 'variable',
        operator: 'greater-than',
      },{
        ref: 'Q1_Q2GroupRef',
        name: 'Q1 = Yes AND Q2 > 3',
        type: 'all',
        rules: ['Q1RuleRef', 'Q2RuleRef']
      },{
        ref: 'Q3_1RuleRef',
        name: 'Q3 = 1',
        variableName: 'Q3',
        variableMatch: '1',
        type: 'variable',
        operator: 'equals',
      },{
        ref: 'Q3_2RuleRef',
        name: 'Q3 = 2',
        variableName: 'Q3',
        variableMatch: '2',
        type: 'variable',
        operator: 'equals',
      },{
        ref: 'Q3GroupRef',
        name: 'Q3 = 1 OR Q3 = 2',
        type: 'all',
        rules: ['Q3_1RuleRef', 'Q3_2RuleRef']
      },{
        ref: 'Q1_Q2_Q3GroupRef',
        name: '(Q1 = Yes AND Q2 > 3) OR (Q3 = 1 OR Q3 = 2)',
        type: 'any',
        rules: ['Q2GroupRef', 'Q3GroupRef']
      },{
        ref: 'Q4RuleRef',
        name: 'Q4 CONTAINS pepsi',
        variableName: 'Q4',
        variableMatch: 'pepsi',
        type: 'variable',
        operator: 'icontains',
      },{
        ref: 'Q1_Q2_Q3_Q4GroupRef',
        name: '((Q1 = Yes AND Q2 > 3) OR (Q3 = 1 OR Q3 = 2))) AND Q4 CONTAINS "Pepsi")',
        type: 'any',
        rules: ['Q1_Q2_Q3GroupRef', 'Q4RuleRef']
      }
    ]
  }

*/
