export default {
  element: {
    editName: 'Edit Name',
    typeName: {
      page: 'page',
      ruleset: 'logic',
      node: 'question'
    },
  },
  page: {
    initial: 'P',
    name: 'Page Name'
  },
  question: {
    label: 'Question Label',
    duplicateLabel: 'Copy of {originalLabel}',
    labelPlaceholder: 'Enter a question label',
    editLabel: 'Edit Label',
    uniqueLabel: 'The label must be unique',
    questionInitial: 'Q',
    questionSpecifyPipe: "{'{{'}open end response{'}}'}",
    questionPipeGenericInsert: '[INSERT PIPE]',
    questionType: 'Question type',
    required: 'Required',
    noQuestionLabel: '[no question text provided]',
    noOptionLabel: '[no answer option text provided]',
    noQuestionType: 'No question type selected',
    answers: 'Answers',
    bulkAnswers: 'Bulk Answers',
    bulkRowAnswers: 'Bulk Row Answers',
    bulkColumnAnswers: 'Bulk Column Answers',
    suggestedAnswers: 'Suggested Answers',
    suggestedColumnAnswers: 'Suggested Column Answers',
    genAIAnswers: 'AI Assistant',
    answerOrder: 'Answer Order',
    answerOrderShuffle: 'Random Order',
    answerOrderNormal: 'Authored Order',
    answerOrderAlphabetized: 'Alphabetized Order',
    questionList: 'Survey List',
    options: 'Options',
    answer: 'Answer',
    columns: 'Columns',
    rows: 'Rows',
    row: 'Row',
    tabs: {
      edit: 'Edit',
      logic: 'Logic',
      addQuota: 'Add Quota',
    },
    actions: {
      addQuestion: 'Add Question',
      addPage: 'Add Page',
      importQuestions: 'Import Questions',
      addLogic: 'Add Logic',
      generateWithAI: 'AI Assistant',
      AIQuestion: 'AI Question',
      AIIntroPage: 'AI Intro Page',
      AIOutroPage: 'AI Outro Page'
    },
    type: {
      info: 'Welcome or Info',
      singleSelect: 'Single Select',
      singleSelectDropdown: 'Single Select Dropdown',
      multiSelect: 'Multi Select',
      text: 'Text Open End',
      numeric: 'Numeric Open End',
      numericSlider: 'Numeric Slider',
      singleSelectGrid: 'Single Select Grid',
      multiSelectGrid: 'Multi Select Grid',
      singleSelectGridScale: 'Rating Scales',
      allocationSlider: 'Allocation Sliders',
      rankOrder: 'Rank Order',
      logic: 'Logic'
    },
    rating: {
      ratingItem: 'Rating item',
      scaleSize: 'Scale size',
      stars: 'Stars',
      hearts: 'Hearts',
      smiles: 'Smiles'
    },
    preview: {
      singleDropdown: {
        label: 'Select option',
        placeholder: 'Select an option',
        searchLabel: 'Search'
      },
      multiDropdown: {
        label: 'Select option',
        placeholder: 'Select an option',
        searchLabel: 'Search'
      },
      text: {
        placeholder: 'Text field',
      }
    },
    bulkAnswersDialog: {
      title: 'Bulk Answers',
      form: {
        title: 'Enter each answer choice on a separate line below:',
        explanation: 'Any existing answer(s) will be overwritten.',
        requiredMessage: 'This field is required'
      }
    },
    logic: {
      routing: {
        logicInitial: 'L',
        name: 'Logic Name',
        question: 'Question',
        afterAnswers: 'After respondent completes {page}:',
        operator: {
          label: 'Operator',
          equals: 'Equals',
          onlyEquals: 'Only equals',
          doesNotEqual: 'Does not equal',
          equalsAny: 'Equals any of',
          equalsAll: 'Equals all of',
          doesNotEqualAny: 'Does not equal any of',
          doesNotEqualAll: 'Does not equal all of',
          greaterThan: 'Is greater than',
          lessThan: 'Is less than',
          greaterThanEqual: 'Is greater than or equal to',
          lessThanEqual: 'Is less than or equal to',
          containsInsensitive: 'Contains',
          doesNotContainInsensitive: 'Does not contain',
          only: 'ONLY',
        },
        answers: 'Answers',
        answer: 'Answer',
        answersUndefined: '[no answers selected]',
        row: 'Row',
        columns: 'Columns',
        ranks: 'Ranks',
        ratings: 'Ratings',
        goTo: {
          label: 'Question or Status',
          endSurvey: 'End of survey as complete',
          terminate: 'End of survey as screen-out',
          thenGoTo: 'Then go to',
          elseGoTo: 'In any other case go to',
          thenGoToSyntax: 'Then go to:',
          elseGoToSyntax: 'In any other case go to:',
          undefined: '[not selected]',
          logicsUpdated: 'The "In any other case go to" field was updated to the subsequent page in the following logic(s):'
        },
        groupType: 'And/Or',
        ifOnlyTrue: 'If the following is true:',
        ifAnyTrue: 'If ANY of the following are true:',
        ifAllTrue: 'If ALL of the following are true:',
        if: 'If',
        then: 'Then',
        is: 'Is',
        for: 'for',
        and: 'And',
        or: 'Or',
        noRuleSet: '[no conditions added]',
        addRule: 'Add condition',
        addRow: 'Add row condition',
        duplicatedCondition: 'You have more than one logic pointing to same answer and last logic will be applied. Logics using same condition:'
      },
    },
    addQuota: {
      completesAllocated: 'Total completes allocated to quotas: {total}'
    },
    suggestedAnswersDialog: {
      title: 'Suggested Answers',
      form: {
        reverseOrder: 'Reverse Order',
        requiredMessage: 'You must choose at least one option'
      }
    },
    questionSettings: {
      button: 'Question Settings',
      title: 'Additional Settings',
      form: {
        maskingAnswer: {
          labelAnswers: 'Masking Answers:',
          labelRows: 'Masking Rows:',
          selectedByRespondents: 'Selected by respondents',
          notSelectedByRespondents: 'Not selected by respondents',
          disabled: 'No eligible questions',
          requiredFieldError: 'This field is required'
        },
      }
    },
    questionOptionsDialog: {
      title: 'Answer Options',
      form: {
        nextTimer: 'Next button timer',
        nextTimerMeasure: 'Secs',
        nextTimerInfo: 'Delay showing the next page button for the indicated number of seconds.',
        answerLayout: 'Answer Layout',
        answerOrder: 'Answer Order',
        select: 'Select',
        randomOptions: {
          random: 'Random Order',
          authored: 'Authored Order',
        },
        columnsOptions: {
          columnsOne: 'One Column Layout',
          columnsTwo: 'Two Column Layout',
          columnsThree: 'Three Column Layout'
        }
      }
    },
    deleteDialog: {
      errorInvalidPosition: 'INVALID POSITION: {name}',
      errorUnsupportedQuestion: 'UNSUPPORTED QUESTION: {name}',
      titleDelete: 'Are you sure you want to delete this {type}?',
      titleReplace: 'Are you sure you want to replace the existing {type}?',
      titleTypeChange: 'Are you sure you want to change the question type?',
      titleMove: 'Are you sure you want to move this {type}?',
      logic: 'logic',
      question: 'question',
      option: 'answer option',
      optionSet: 'answer options',
      page: 'page',
      ruleSet: 'logic',
      content: 'All changes will be lost',
      continue: 'Would you like to proceed?',
      // message
      requirePageBeforeLogic: 'There must be at least one page preceding a logic element.',

      // confirmation dialog messaging naming convention: action + element initiated action + specific feature checked
      // logic
      deleteNodeCondition: 'This question is used in logic condition(s). Deleting it will remove it from condition(s) in the following logic(s):',
      deletePageCondition: 'Question(s) on this page are used in logic condition(s). Deleting it will remove them from condition(s) in the following logic(s):',
      deleteOptionsetCondition: 'One or more answer options are used in logic condition(s). Deleting it will remove the option(s) from conditions in the following logic(s):',
      deleteOptionCondition: 'This answer option is used in logic condition(s). Deleting it will remove it from the following logic(s):',
      deletePageGoTo: 'This page is used in the "Then go to" field in logic(s). Deleting it will remove the value of this field in the following logic(s)',
      deletePageElseGoTo: 'This page is used in the "In any other case go to" field in logic(s). Deleting it will change the value of this field in the following logic(s)',

      changetypeNodeCondition: 'This question is used in logic condition(s). Changing the question type may impact conditions in the following logic(s):',
      changetypeNodeConditionUnsupported: 'This question is used in logic condition(s). The "{type}" question type is not supported in conditions and will be removed from the following logic(s):',

      moveRulesetCondition: 'Questions in logic conditions must be asked before the logic. In the new position, condition(s) referencing the following question(s) will be invalid:',
      moveRulesetGoTo: 'Pages selected in the "Then go to" field must be asked after the logic. In the new position, the "Then go to" field referencing the following page will be invalid:',
      moveRulesetElseGoTo: 'Pages selected in the "In any other case go to" field must be asked after the logic. In the new position, the "In any other case go to" field referencing the following page maybe become invalid:',

      moveNodeCondition: 'This question is used in logic condition(s). In the new position, the following logic(s) referencing this question will be invalid:',
      movePageCondition: 'Question(s) on this page are used in logic condition(s). In the new position, the following logic(s) referencing these question(s) will be invalid:',
      movePageGoTo: 'Pages selected in the "Then go to" field must be asked after the logic. In the new position, the "Then go to" field in the following logic(s) will be invalid:',
      movePageElseGoTo: 'Pages selected in the "In any other case go to" field must be asked after the logic. In the new position, the "In any other case go to" field in the following logic(s) may become invalid:',

      // masking
      deleteNodeMaskingParent: 'This question is used to mask answers. Deleting it will remove masking in the following question(s):',
      deletePageMaskingParent: 'Question(s) on this page are used to mask answers. Deleting it will remove masking in the following question(s):',
      deleteOptionsetMaskingParent: 'These answer options are used in masking. They will also be replaced in the following question(s):',
      deleteOptionMaskingParent: 'This answer option is used in masking. It will also be removed in the following question(s):',

      changetypeNodeMaskingParent: 'This question is used to mask answers. Changing the question type may impact masking in the following question(s):',
      changetypeNodeMaskingParentUnsupported: 'This question is used to mask answers. Masking is not supported for the "{type}" question type and will be removed from the following question(s):',

      moveNodeMaskingChild: 'This question has masking applied. The question selected for masking must be asked before the question being masked. In the new position, the masking applied in this question will be invalid.',
      movePageMaskingChild: 'Question(s) on this page have masking applied. The question selected for masking must be asked before the question being masked. In the new position, the masking applied in the following question(s) will be invalid:',
      moveNodeMaskingParent: 'This question is used to mask answers. The question selected for masking must be asked before the question being masked. In the new position, the masking applied in the following question(s) will be invalid:',
      movePageMaskingParent: 'Question(s) on this page are used to mask answers. The question selected for masking must be asked before the question being masked. In the new position, the masking applied in the following question(s) will be invalid:',

      // piping
      deleteNodePipingParent: 'This question is used as a pipe. Deleting it will remove these pipe(s) in the following question(s):',
      deletePagePipingParent: 'Question(s) on this page are used as a pipe. Deleting it will remove these pipe(s) in the following question(s):',

      changetypeNodePipingParent: 'This question is used as a pipe. Changing the question type may change the value of these pipe(s) in the following question(s):',
      changetypeNodePipingParentUnsupported: 'This question is used as a pipe. Piping is not supported for the "{type}" question type and will need to be updated in the following question(s):',

      moveNodePipingChild: 'This question has pipe(s). Question pipes can only be added after the question is asked. In the new position, the following question pipe(s) will be invalid:',
      movePagePipingChild: 'Question(s) on this page have pipes. Question pipes can only be added after the question is asked. After moving this question, the following pipes will be invalid:',
      moveNodePipingParent: 'This question is used as a pipe. Question pipes can only be added after the question is asked. After moving this page, the following question(s) will have an invalid pipe:',
      movePagePipingParent: 'Question(s) on this page are used as a pipe. Question pipes can only be added after the question is asked. In it\'s new position, the following question(s) will have an invalid pipe:',
    },
    importQuestionsDialog: {
      title: 'Import Questions',
      selectLabel: 'Survey Status',
      back: 'Back to Survey list',
      confirm: 'Import Questions',
      questionToImport: 'Select to import',
      continue: 'Continue',
      surveyName: 'Survey Name',
      status: 'Status',
      questions: 'Questions',
      questionText: 'Question text',
      optionQuota: '[Required Quota: {count}]',
    },
    search: {
      placeholder: 'Search',
      filters: 'Quick Filters',
      myself: 'Created myself',
      'date-range': 'Select date range'
    },
    surveyStatus: {
      planned: 'Planned',
      field: 'Field',
      finished: 'Finished',
      archived: 'Archived',
    },
    clearDraftDialog: {
      title: 'The existing draft will be removed',
      content: 'Are you sure you want to proceed from scratch? This means all your previous questions and editing will be deleted. By clicking apply you will start over.',
      error: {
        title: 'Unable to start from scratch',
        unknown: 'Cannot proceed from scratch - an unknown error occurred',
      }
    },
    confirmationPublishDialog: {
      title: 'Publish your survey',
      content1: 'Before publishing your survey please ensure all required demographic questions, screeners, and survey questions are in place.',
      content2: 'Are you sure you want to submit the survey?',
      cancelButton: 'Check setup',
      confirmButton: 'Publish survey'
    },
    genAIAnswersDialog: {
      title: 'Generate AI Answer Choices',
      promptLabel: 'Describe your request to the AI system',
      promptPlaceholder: 'e.g Milk brands',
      selectAll: 'Select All',
      unselectAll: 'Unselect All',
      searchLabel: 'Search',
      generatingAnswers: 'Generating Answers',
      optionsTitle: 'Select the answers you want to add to your survey',
    },
    genAIQuestionDialog: {
      title: 'Generate Question with AI',
      promptLabel: 'Describe your request to the AI system',
      promptPlaceholder: 'e.g Assessing customer satisfaction with online grocery shopping experience',
      typeLabel: 'Question type',
      noSelection: 'No Selection',
      generatingQuestion: 'Generating Question',
      moreOptions: 'More Options',
      generateQuestion: 'Generate Question',
      useSelection: 'Use this selection'
    },
    genAITextDialog: {
      intro: {
        title: 'AI Intro Page',
        generating: 'Generating Intro',
      },
      outro: {
        title: 'AI Outro Page',
        generating: 'Generating Outro',
      },
      useOption: 'Use this option',
    },
    tooltips: {
      editLabel: 'Question Label is used for reporting.',
      saveChanges: 'Save Changes',
      cancelChanges: 'Cancel Changes',
      duplicate: 'Duplicate {element}',
      delete: 'Delete {element}',
      previousElement: 'Previous',
      nextElement: 'Next',
      genAIAnswers: 'Generate AI Answer Choices'
    },
    rte: {
      questionLabel: 'Question text',
      infoLabel: 'Welcome or info text'
    },
    aiAssistant: {
      generatingWordingReview: 'Generating wording review',
      label: 'AI Assistant'
    },
    media: {
      clickOrDrop: 'Click or drop images to include',
      dropFiles: 'Drop PNG, JPG/JPEG, GIF or SVG. Max file size is 16MB.',
      actions: {
        small: 'Small',
        original: 'Original size',
        altText: 'Alt text',
        delete: 'Delete'
      }
    },
    altImageDialog: {
      title: 'Alt Text',
      label: 'Alternative description ( will not be shown to users)'
    }
  }
}
