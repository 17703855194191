import {BaseNodeParams, NodeTranslations, NodeTypeId} from "@/models/Node";
import {OptionSetParams} from "@/models/nodeTypes/QuestionType1d";
import QuestionTypeAllocation1d, {
  QuestionTypeAllocation1dOrdinalities
} from "@/models/nodeTypes/QuestionTypeAllocation1d";
import {isFloat} from "@/utils/string";
import Survey from "@/models/Survey";
import {cloneDeep} from "@/utils/lodash";
import QuestionTypeNumeric from "@/models/nodeTypes/QuestionTypeNumeric";

export default class QuestionTypeAllocationSlider<
  TQuestionTypeAllocationSliderOrdinalities extends QuestionTypeAllocationSliderOrdinalities = QuestionTypeAllocationSliderOrdinalities
> extends QuestionTypeAllocation1d<TQuestionTypeAllocationSliderOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-gesture-tap';
  static readonly NODE_TITLE: string = 'question.type.allocationSlider';
  static readonly NODE_EDIT_COMPONENT: string = 'QuestionTypeAllocationSliderForm';
  static readonly DEFAULT_ORDINALITIES: QuestionTypeAllocationSliderOrdinalities = {
    ...QuestionTypeAllocation1d.DEFAULT_ORDINALITIES,
    //TODO: add slider defaults when support in future
  };
  static readonly DISPLAY_STEP: boolean = true;
  static readonly DISPLAY_MIN: boolean = false;

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionTypeAllocationSlider.DEFAULT_ORDINALITIES) as TQuestionTypeAllocationSliderOrdinalities,
    i18n: NodeTranslations = {},
    optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.AllocationSlider;
  }

  validateOrdinalities(survey: Survey) {
    super.validateOrdinalities(survey);

    // hardcoding these for now, as not supporting in V1
    this.ordinalities.min = QuestionTypeAllocationSlider.DEFAULT_ORDINALITIES.min;
    this.ordinalities.allocation.totalValue = this.ordinalities.max;

    // hardcode decimals for now based on whether decimals exist in any fields
    if (isFloat(this.ordinalities.step) || isFloat(this.ordinalities.min) || isFloat(this.ordinalities.max)) {
      this.ordinalities.decimals = QuestionTypeNumeric.DEFAULT_ORDINALITIES_DECIMALS;
    } else {
      this.ordinalities.decimals = QuestionTypeNumeric.DEFAULT_ORDINALITIES_NO_DECIMALS;
    }
  }
}

export interface QuestionTypeAllocationSliderOrdinalities extends QuestionTypeAllocation1dOrdinalities {
  //TODO: not supporting in V1, setting as optional for now
  slider?: {
    inputs: boolean,
    labels: {
      left: string | null,
      right: string | null,
    },
  },
}
