export default {
  builder: {
    startSurvey: 'Start your survey!',
    draftSurvey: {
      title: '{name} (Draft)',
      defaultName: 'Survey',
    },
    reuseSurvey: {
      title: 'Re-Use Survey',
      productName: 'Product {name}'
    },
    ai: {
      title: 'Selecting a survey type assists our AI with creating questions that fit your research needs',
      surveyTypeTitle: 'Survey Type',
      topicTitle: 'Describe your request to the AI system',
      topicPlaceholder: 'e.g Assessing customer satisfaction with online grocery shopping experience',
      brandTitle: 'Brand name',
      brandPlaceholder: 'Type brand name here',
      campaignNameTitle: 'Campaign name',
      campaignNamePlaceholder: 'Type campaign name here',
      conceptTitle: 'Concept name',
      conceptPlaceholder: 'Type concept name here',
      conceptDescriptionTitle: 'Concept description',
      conceptDescriptionPlaceholder: 'Type concept description here',
      questionsTitle: 'Number of Questions',
      mediaTypeTitle: 'Media Type',
      mediaText: 'Text',
      generateSurvey: 'Generate Survey',
      imagePreview: 'Image Preview',
      dialog: {
        title: 'Generate New Survey?',
        description: 'Are you sure you want to generate a new survey? This action will overwrite the already generated questions.',
        serverError: 'We\'re sorry, our AI couldn\'t complete your request. Please try to generate the survey again',
      },
      generatedPreviewTitle: 'Generated questions preview',
      messagesLoading: {
        message1: 'Please bear with us as we prepare the survey',
        message2: 'We\'re currently in the process of building the survey, so it may take a little while longer',
        message3: 'We apologize for the delay, but the survey is still being prepared'
      },
      surveyTypes: {
        general: 'General',
        brandPerception: 'Brand Perception',
        contactCentre: 'Contact Centre',
        customerExperience: 'Customer Experience',
        onlinePostPurchase: 'Online Post Purchase',
        opinion: 'Opinion',
        preAndPost: 'Pre And Post',
        storeLocation: 'Store Location',
        usurv: 'Usurv',
        brandHealth: 'Brand Health',
        campaignTrackingPost: 'Campaign Tracking Post',
        campaignTrackingPre: 'Campaign Tracking Pre',
        conceptTest: 'Concept Test',
      },
      mediaTypes: {
        text: 'Text',
        picture: 'Picture',
        video: 'Video'
      },
      required: 'This field is required'
    },
    actions: {
      backHome: 'Survey Creation Options',
      previewSurvey: 'Preview Survey',
      previewSurveyDisabled: 'The survey cannot be previewed until all errors are resolved.',
      proceed: 'Proceed to Confirmation',
      proceedDisabled: 'The survey cannot be confirmed until all errors are resolved.',
      undo: 'Undo Change',
      redo: 'Redo Change',
      continueEditing: 'Continue editing',
      applySurvey: 'Apply survey',
      viewSurvey: 'View survey',
      applyTemplate: 'Apply template'
    },
    errors: {
      relatedSurvey: 'We\'re sorry, we couldn\'t load your past surveys successfully. Please try again later.',
      templates: 'We\'re sorry, we couldn\'t load your templates successfully. Please try again later.',
      draftSurveys: 'We\'re sorry, we couldn\'t load your draft surveys successfully. Please try again later.',
      generic: 'We\'re sorry, an unexpected error has occurred. Please try refreshing your browser. If this error persists, please reach out to support for assistance.',
      model: {
        generic: {
          ruleset: 'This logic contains errors. Please review.',
          node: 'This question contains errors. Please review.',
          page: 'This page contains errors. Please review.',
        },
        empty: {
          page: {
            nodes: 'Pages must contain at least one question. Please add a question or delete the page.'
          },
          node: {
            text: 'Question text must contain content. Please add text or an image or delete the question.',
            ordinalities: {
              numeric: {
                min: 'Min is required.',
                max: 'Max is required.',
                step: 'Steps is required.',
              },
            },
          },
          optionSet: {
            options: 'This question type requires at least one answer option. Please add an answer or change the question type.'
          },
          option: {
            text: 'An answer option must contain content. Please add text or an image or delete the option.'
          },
          ruleSet: {
            goTo: 'Please select a value.',
            elseGoTo: 'Please select a value.',
          },
          rule: {
            node: 'Please select a question.',
            operator: 'Please select an operator.',
            valueSet1: 'Please select an option.',
            valueSet2: 'Please select an option.',
            value: 'Please enter an answer.'
          },
        },
        position: {
          rule: {
            node: 'Conditions can only be based on previous questions.'
          },
          ruleSet: {
            goTo: 'The go to value cannot be a previous page.',
            elseGoTo: 'The go to value cannot be a previous page.',
          },
          option: {
            piping: 'The following piped question(s) are asked on the same or subsequent page(s): {pipe}'
          },
          node: {
            piping: 'The following piped question(s) are asked on the same or subsequent page(s): {pipe}'
          },
          optionMasking: {
            set1: 'Masking is based on a question on the same or subsequent page.'
          }
        },
        invalid: {
          rule: {
            node: 'The question selected is not supported in logic conditions.'
          },
          option: {
            piping: 'The following piped question(s) aren\'t supported for piping: {pipe}'
          },
          node: {
            piping: 'The following piped question(s) aren\'t supported for piping: {pipe}',
            ordinalities: {
              numeric: {
                range: 'The maximum value must be greater than the minimum.',
                step: 'The steps value must be less than the maximum.'
              },
              numericSlider: {
                intervals: 'The range must be divisible by the step size. Please update the min, max, or step values to ensure even intervals within the range.',
              }
            },
          },
        },
        nonExistant: {
          rule: {
            node: 'The question that was selected no longer exists.'
          },
          option: {
            piping: 'The following piped question(s) do not exist: {pipe}'
          },
          node: {
            piping: 'The following piped question(s) do not exist: {pipe}'
          },
          optionMasking: {
            set1: 'Masking is based on a question that no longer exists. Please review and update.'
          }
        },
        length: {
          option: {
            text: 'Answer text cannot exceed {max} characters.'
          },
          node: {
            text: 'Question text cannot exceed {max} characters.'
          },
        }
      },
    },
    saved: 'Saved',
    questionCount: '{count} questions'
  }
}
