import {OptionSetParams} from "./QuestionType1d";
import {BaseNodeParams, NodeTranslations, NodeTypeId} from "@/models/Node";
import QuestionTypeSingleSelect, {
  QuestionTypeSingleSelectOrdinalities
} from "@/models/nodeTypes/QuestionTypeSingleSelect";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeSingleSelectDropDown<
  TQuestionTypeSingleSelectDropDownOrdinalities extends QuestionTypeSingleSelectDropDownOrdinalities = QuestionTypeSingleSelectDropDownOrdinalities
> extends QuestionTypeSingleSelect<TQuestionTypeSingleSelectDropDownOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-form-dropdown';
  static readonly NODE_TITLE: string = 'question.type.singleSelectDropdown';
  static readonly DEFAULT_ORDINALITIES: QuestionTypeSingleSelectDropDownOrdinalities = {};
  static readonly NODE_SET1_SUPPORTS_EXCLUSIVE: boolean = false;
  static readonly NODE_SET1_SUPPORTS_SPECIFY: boolean = false;

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionTypeSingleSelectDropDown.DEFAULT_ORDINALITIES) as TQuestionTypeSingleSelectDropDownOrdinalities,
    i18n: NodeTranslations = {},
    optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.SingleSelectDropDown;
  }
}

export interface QuestionTypeSingleSelectDropDownOrdinalities extends QuestionTypeSingleSelectOrdinalities {}
