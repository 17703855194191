<template>
  <div data-test-id="entry-point">
    <router-view v-if="shellStore.initialized && shellStore.orgToken"/>
    <div class="mx-6 my-8" v-else-if="!shellStore.failed">
      {{ $t('alert.loading')}}
    </div>
    <div v-else>
      {{ $t('alert.couldNotLoad') }}
    </div>
  </div>
</template>

<script lang="ts">
import {useTheme} from 'vuetify';
import {defineComponent} from 'vue';
import {applyTheme} from '@maru/vuetify-theme';
import {DialogRoute} from '@maru/hub-vuetify-components'
import webBridge, {ShellStoreState, Theme, useShellStore} from '@maru/shell-app';
import mainBridge, {MainEvent, MainStoreType, useMainStore} from '@maru/main-app';
import {useAppStore} from "@/store/app";

declare const pendo: {
  getVisitorId: Function,
  updateOptions: Function,
  initialize: Function
};

export default defineComponent({
  name: 'App',
  setup() {
    const shellStore: ShellStoreState = useShellStore();
    const mainStore: MainStoreType = useMainStore();
    const appStore = useAppStore();
    const theme = useTheme();

    if (!DialogRoute.isActive()) {
      webBridge.ready({
        store: shellStore,
        dialogUrl: DialogRoute.url(),
        onChangeTheme: (newTheme: Theme) => applyTheme.call(this, newTheme, theme)
      });
    } else {
      webBridge.ready({
        store: shellStore,
        onChangeTheme(newTheme: Theme) {
          applyTheme.call(this, newTheme, theme);
        }
      })
    }

    return { shellStore, mainStore, appStore };
  },
  watch: {
    'shellStore.user': {
      immediate: true,
      handler(user) {
        if (user?.id) {
          const account = user.orgsList?.find(org => org.id === this.shellStore.org.id);
          const metadata = {
            visitor: {
              id: user.id,
              name: user.name,
              locale: user.locale,
              email: user.email,
              timezone: user.timezone,
              defaultOrgId: user.defaultOrgId
            },
            account: {
              id: account?.id,
              name: account?.name
            }
          };

          pendo.initialize(metadata);
        }
      }
    },
    'shellStore.user.locale': {
      immediate: true,
      handler(locale) {
        if (locale) {
          const baseLocale = locale.split('_')[0];
          this.$i18n.fallbackLocale = [baseLocale, 'en'];
          if (this.$i18n.availableLocales.includes(locale)) {
            this.$i18n.locale = locale;
          } else {
            this.$i18n.locale = baseLocale;
          }
        }
      }
    },
  },
  computed: {
    rtlDirection(){
      return this.appStore.rtlDirection;
    }
  },
  mounted() {
    this.appStore.setDirection();
    this.setBodyDirectionClass();
    mainBridge.ready(
      { store: this.mainStore },
      (type: MainEvent, payload: any) => {
        if (type === MainEvent.Location && payload.childPath !== undefined) {
          const newFullPath = `/${payload.childPath}`;
          if (this.$route.path !== newFullPath) {
            this.$router.push(newFullPath);
          }
        }
      }
    )
  },
  methods: {
    setBodyDirectionClass() {
      const directionClass = this.rtlDirection ? 'rtl-direction': 'ltr-direction';

      document.body.classList.add(directionClass)
    }
  }
});
</script>

<style lang="scss">
@import "@/styles/main";
</style>
