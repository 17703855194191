import {BaseNodeParams, HIDDEN_NODE_TYPES, NodeOrdinalities, NodeTranslations, NodeTypeId} from "@/models/Node";
import Node from "@/models/Node";
import NodeTypeInfo from "@/models/nodeTypes/NodeTypeInfo";
import QuestionTypeText from "@/models/nodeTypes/QuestionTypeText";
import QuestionTypeSingleSelect from "@/models/nodeTypes/QuestionTypeSingleSelect";
import QuestionTypeSingleSelectDropDown from "@/models/nodeTypes/QuestionTypeSingleSelectDropDown";
import QuestionTypeMultiSelect from "@/models/nodeTypes/QuestionTypeMultiSelect";
import QuestionTypeNumeric from "@/models/nodeTypes/QuestionTypeNumeric";
import QuestionTypeNumericSlider from "@/models/nodeTypes/QuestionTypeNumericSlider";
import QuestionTypeSingleSelectGrid from "@/models/nodeTypes/QuestionTypeSingleSelectGrid";
import QuestionTypeMultiSelectGrid from "@/models/nodeTypes/QuestionTypeMultiSelectGrid";
import QuestionTypeRankOrder from "@/models/nodeTypes/QuestionTypeRankOrder";
import QuestionTypeSingleSelectGridScale from "@/models/nodeTypes/QuestionTypeSingleSelectGridScale";
import QuestionTypeAllocationSlider from "@/models/nodeTypes/QuestionTypeAllocationSlider";
import i18n from "@/plugins/i18n";
import {OptionSetParams} from "@/models/nodeTypes/QuestionType1d";

type NodeConstructor = new (...args: any[]) => Node;

export const nodeTypeClasses: Record<NodeTypeId, NodeConstructor> = {
  [NodeTypeId.SingleSelect]: QuestionTypeSingleSelect,
  [NodeTypeId.SingleSelectDropDown]: QuestionTypeSingleSelectDropDown,
  [NodeTypeId.MultiSelect]: QuestionTypeMultiSelect,
  [NodeTypeId.Text]: QuestionTypeText,
  [NodeTypeId.Numeric]: QuestionTypeNumeric,
  [NodeTypeId.NumericSlider]: QuestionTypeNumericSlider,
  [NodeTypeId.SingleSelectGrid]: QuestionTypeSingleSelectGrid,
  [NodeTypeId.MultiSelectGrid]: QuestionTypeMultiSelectGrid,
  [NodeTypeId.SingleSelectGridScale]: QuestionTypeSingleSelectGridScale,
  [NodeTypeId.AllocationSlider]: QuestionTypeAllocationSlider,
  [NodeTypeId.RankOrder]: QuestionTypeRankOrder,
  [NodeTypeId.Info]: NodeTypeInfo,
}

export function createNode(
  {
    type,
    survey,
    ref = null,
    code = null,
    name = null,
    text = null,
    rule = null,
    hide = null,
    require = null,
    assets = null,
  }: BaseNodeParams,
  nodeOrdinalities: NodeOrdinalities = {},
  nodeI18n: NodeTranslations = {},
  optionSetParams: OptionSetParams = {}
): Node | null {

  const NodeTypeClass = nodeTypeClasses[type as NodeTypeId];
  if (!NodeTypeClass || typeof NodeTypeClass !== 'function') {
    return null;
  }

  return new NodeTypeClass(
    {
      type,
      survey,
      ref,
      code,
      name,
      text,
      rule,
      hide,
      require,
      assets
    },
    nodeOrdinalities,
    nodeI18n,
    optionSetParams,
  );
}

export function getNodeTypes(): NodeTypeText[] {
  return Object.entries(nodeTypeClasses).map(
    ([id, nodeTypeClass]) => ({
      id: id as NodeTypeId,
      title: i18n.global.t(nodeTypeClass['NODE_TITLE']),
      icon: nodeTypeClass['NODE_ICON'],
    })).filter(type => !HIDDEN_NODE_TYPES.includes(type.id));
}


export interface NodeTypeText {
  id: NodeTypeId,
  title: string,
  icon: string,
}
