import Node, {
  NodeTypeId,
  BaseNodeParams,
  NodeOrdinalities,
  NodeTranslations,
  NodeOrdinalitiesTypes
} from "@/models/Node";
import {isNumber, isFloat} from "@/utils/string";
import Survey from "@/models/Survey";
import SurveyError, {
  SurveyErrorProperty, SurveyErrorType
} from "@/models/errors/SurveyError";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeNumeric<
  TQuestionTypeNumericOrdinalities extends QuestionTypeNumericOrdinalities = QuestionTypeNumericOrdinalities
> extends Node<TQuestionTypeNumericOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-numeric';
  static readonly NODE_TITLE: string = 'question.type.numeric';
  static readonly NODE_EDIT_COMPONENT: string = 'QuestionTypeNumericForm';
  static readonly NODE_REQUIRE: boolean = true;
  static readonly DEFAULT_ORDINALITIES_DECIMALS = 2;
  static readonly DEFAULT_ORDINALITIES_NO_DECIMALS = 0;
  static readonly DEFAULT_ORDINALITIES_STEP_DECIMALS = 0.01;
  static readonly DEFAULT_ORDINALITIES_STEP_INTEGER = 1;
  static readonly SUPPORTED_DECIMALS = [0, 2];
  static readonly DEFAULT_ORDINALITIES: QuestionTypeNumericOrdinalities = {
    min: null,
    max: null,
    step: QuestionTypeNumeric.DEFAULT_ORDINALITIES_STEP_INTEGER,
    decimals: 0,
  };
  static readonly ORDINALITY_TYPES: NodeOrdinalitiesTypes = {
    min: ['number', 'null'],
    max: ['number', 'null'],
    step: ['number', 'null'],
    decimals: ['number', 'null'],
  };
  static readonly DISPLAY_MIN: boolean = true;
  static readonly DISPLAY_DECIMALS: boolean = true;
  static readonly DISPLAY_STEP: boolean = false;

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionTypeNumeric.DEFAULT_ORDINALITIES) as TQuestionTypeNumericOrdinalities,
    i18n: NodeTranslations = {}
  ) {
    super(baseNodeParams, ordinalities, i18n);

    this.type = NodeTypeId.Numeric;
  }

  validateOrdinalities(survey: Survey) {
    super.validateOrdinalities(survey);

    // just ensure decimals are set to supported value
    this.ordinalities.decimals = QuestionTypeNumeric.SUPPORTED_DECIMALS.includes(this.ordinalities.decimals)
      ? this.ordinalities.decimals
      : QuestionTypeNumeric.DEFAULT_ORDINALITIES.decimals;

    // update to decimals if min/max has a decimal
    if (!this.allowsDecimals() && (isFloat(this.ordinalities.min) || isFloat(this.ordinalities.max))) {
      this.ordinalities.decimals = QuestionTypeNumeric.DEFAULT_ORDINALITIES_DECIMALS;
    }

    // hardcode step for now based on decimals, if not displayed
    if (!this.constructor['DISPLAY_STEP']) {
      this.ordinalities.step = this.ordinalities.decimals > 0
        ? QuestionTypeNumeric.DEFAULT_ORDINALITIES_STEP_DECIMALS
        : QuestionTypeNumeric.DEFAULT_ORDINALITIES_STEP_INTEGER;
    }

    // don't allow more than 2 decimal places for now, drop extras
    const { min, max, step } = this.ordinalities;
    this.ordinalities.min = isFloat(min) ? Math.trunc(min * 100) / 100 : min;
    this.ordinalities.max = isFloat(max) ? Math.trunc(max * 100) / 100 : max;
    this.ordinalities.step = isFloat(step) ? Math.trunc(step * 100) / 100 : step;

    // if set, max must be greater than min
    if (this.hasMin() && this.hasMax() && this.ordinalities.max <= this.ordinalities.min) {
      survey.errors.push(new SurveyError(SurveyErrorType.Invalid, SurveyErrorProperty.NodeOrdinalitiesNumericRange, this.ref));
    }
  }

  hasMin(): boolean {
    return isNumber(this.ordinalities.min);
  }

  hasMax(): boolean {
    return isNumber(this.ordinalities.max);
  }

  hasStep(): boolean {
    return isNumber(this.ordinalities.step);
  }

  allowsDecimals(): boolean {
    return this.ordinalities.decimals > 0;
  }
}

export interface QuestionTypeNumericOrdinalities extends NodeOrdinalities {
  min: number | null,
  max: number | null,
  step: number | null,
  decimals: number | null,
}
