import { uuid } from "@/utils/string";
import Survey from "@/models/Survey";
import RuleGroup from "@/models/RuleGroup";
import Rule from "@/models/Rule";

export default class Quota {
  constructor(
    survey: Survey,
    public ref: string = null,
    //TODO: need a decision on naming to use in field reporting...guessing answer option label
    // Assume glue app would combine group and quota name and set that to variableName in QApp (i.e. Q1. Red), but might make sense to add a name prop to the action in QApp and expose for reporting
    // QApp example of quotas: https://test01-questions.uk01.test.maruhub.dev/fiddle/8355257235887342660/6608849614938741521
    // Note: same applies to DQs, the name of a DQ will be needed in reporting, but we're not enforcing a name in the UI...we might need to or otherwise, use the logic code if not set (i.e. L1 screen-out),
    // A name won't be needed for skips as only OQ and DQ counts will be presented in field reporting so might be a bit annoying to force them to add a name to logics overall.
    public name: string = null,
    public rule: Rule | RuleGroup = null,
    public count: number = null,
  ) {
    this.ref = this.ref ?? uuid();
  }
}
