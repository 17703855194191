import { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { createAxiosDateTransformer } from 'axios-date-transformer';
import {isDevEnv} from "@/constants/config";
import webBridge from "@maru/shell-app";

const IS_DEV = isDevEnv();

export class ApiService {
    private axiosInstance: AxiosInstance;

    constructor(baseURL: string) {
        this.axiosInstance = createAxiosDateTransformer({baseURL})
    }

    async get<T>(endpoint: string, config: AxiosRequestConfig = {}): Promise<T> {
        try {
          IS_DEV && console.debug(`calling service ${endpoint}`);
            const response: AxiosResponse<T> = await this.axiosInstance.get(
                `/${endpoint}`,
                config,
            );
          IS_DEV && console.debug(`response from service ${endpoint}: `, response.data)
            return response.data;
        } catch (error) {
            console.error('GET request failed:', error);
            throw HttpError.fromResponse(error.response);
        }
    }

    async post<T>(endpoint: string, data: object, config: AxiosRequestConfig = {}): Promise<T> {
        try {
          IS_DEV && console.debug(`calling service ${endpoint} and payload: `, data);
            const response: AxiosResponse<T> = await this.axiosInstance.post(
                `/${endpoint}`,
                data,
                config,
            );
          IS_DEV && console.debug(`response from service ${endpoint}: `, response.data);

            return response.data;
        } catch (error) {
            console.error('POST request failed:', error);
            throw HttpError.fromResponse(error.response);
        }
    }

    async patch<T>(endpoint: string, data: object, config: AxiosRequestConfig = {}): Promise<T> {
        try {
          IS_DEV && console.debug(`calling service ${endpoint} and payload: `, data);
            const response: AxiosResponse<T> = await this.axiosInstance.patch(
                `/${endpoint}`,
                data,
                config
            );
          IS_DEV && console.debug(`response from service ${endpoint}: `, response.data);

            return response.data;
        } catch (error) {
            console.error('PATCH request failed:', error);
            throw HttpError.fromResponse(error.response);
        }
    }

    async delete<T>(endpoint: string, config: AxiosRequestConfig = {}): Promise<T> {
        try {
          IS_DEV && console.debug(`calling service ${endpoint}`);
            const response: AxiosResponse<T> = await this.axiosInstance.delete(
                `/${endpoint}`,
                config
            );
          IS_DEV && console.debug(`response from service ${endpoint}: `, response.data);

            return response.data;
        } catch (error) {
            console.error('DELETE request failed:', error);
            throw HttpError.fromResponse(error.response);
        }
    }
}

export class HttpError extends Error {
    status: number;
    data: any;

    constructor(message: string, status: number, data: any = null) {
        super(message);
        this.status = status;
        this.data = data;
    }

    static fromResponse(response: any): HttpError {
        const { status, statusText, data } = response;

        switch (status) {
            case 400:
                return new BadRequest(statusText, status, data);

            case 401:
                return new Unauthorized(statusText, status, data);

            case 403:
                return new Forbidden(statusText, status, data);

            case 404:
                return new NotFound(statusText, status, data);

            case 422:
                return new ClientRequest(statusText, status, data);

            case 423:
                return new Locked(statusText, status, data);

            case 500:
                return new InternalServerError(statusText, status, data);

            default:
                return new HttpError(statusText, status, data);
        }
    }
}

export class BadRequest extends HttpError {}
export class Forbidden extends HttpError {}
export class InternalServerError extends HttpError {}
export class NotFound extends HttpError {}
export class Unauthorized extends HttpError {
  constructor(public statusText: string, public status: number, public data: any) {
    super(statusText, status, data);
    if (data.error === 'token_exception') {
      console.warn("Refreshing project context...");
      webBridge.refreshToken('projectContext');
    }
  }
}
export class Locked extends HttpError {}
export class ClientRequest extends HttpError {}
