import {BaseNodeParams, NodeOrdinalitiesTypes, NodeTranslations} from "@/models/Node";
import QuestionType1d, {OptionSetParams, QuestionType1dOrdinalities} from "@/models/nodeTypes/QuestionType1d";
import QuestionTypeNumeric, {QuestionTypeNumericOrdinalities} from "@/models/nodeTypes/QuestionTypeNumeric";
import {cloneDeep} from "@/utils/lodash";

/**
 * Numeric allocation question types with 1 option set, such as allocation sliders.
 */
export default abstract class QuestionTypeAllocation1d<
  TQuestionTypeAllocation1dOrdinalities extends QuestionTypeAllocation1dOrdinalities = QuestionTypeAllocation1dOrdinalities
> extends QuestionType1d<TQuestionTypeAllocation1dOrdinalities> {

  static readonly DEFAULT_ORDINALITIES: QuestionTypeAllocation1dOrdinalities = {
    min: 0,
    max: 100,
    step: 1,
    decimals: 0,
    allocation: {
      // validateTotal: false, //TODO: not supporting in V1
      totalValue: 100,
    },
  };

  static readonly ORDINALITY_TYPES: NodeOrdinalitiesTypes = {
    ...QuestionTypeNumeric.ORDINALITY_TYPES,
    allocation: {
      // validateTotal: ['boolean'], //TODO: not supporting in V1
      totalValue: ['number', 'null'],
    },
  };

  protected constructor(
      baseNodeParams: BaseNodeParams,
      ordinalities = cloneDeep(QuestionTypeAllocation1d.DEFAULT_ORDINALITIES) as TQuestionTypeAllocation1dOrdinalities,
      i18n: NodeTranslations = {},
      optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);
  }
}

export interface QuestionTypeAllocation1dOrdinalities extends QuestionType1dOrdinalities, QuestionTypeNumericOrdinalities {
  allocation: {
    validateTotal?: boolean, //TODO: not supporting in V1
    totalValue: number | null,
    // TODO: QApp also supports texts - doesn't look like we're supporting initially so could hardcode here or glue app adds.
    //  model will depend on design for DIYers, perhaps some preset appends/prepends - reason it's a pipe like this though is for
    //  flexibility in translation differences, i.e. $ is before the value in english, but after in french
    //    texts:
    //      valueTemplate: "${value.2}" // format of individual sliders - .2 is number of decimal places
    //      totalValueTemplate: "${current.2} / ${target.2}" // format of total value
  }
}
