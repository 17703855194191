import {NodeTypeId, BaseNodeParams, NodeTranslations} from "@/models/Node";
import QuestionTypeNumeric, {QuestionTypeNumericOrdinalities} from "@/models/nodeTypes/QuestionTypeNumeric";
import {isFloat} from "@/utils/string";
import Survey from "@/models/Survey";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeNumericSlider<
  TQuestionTypeNumericSliderOrdinalities extends QuestionTypeNumericSliderOrdinalities = QuestionTypeNumericSliderOrdinalities
> extends QuestionTypeNumeric<TQuestionTypeNumericSliderOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-gesture-tap';
  static readonly NODE_TITLE: string = 'question.type.numericSlider';
  static readonly NODE_EDIT_COMPONENT: string = 'QuestionTypeNumericForm';
  static readonly NODE_REQUIRE: boolean = undefined;
  static readonly DEFAULT_ORDINALITIES: QuestionTypeNumericSliderOrdinalities = {
    min: 1,
    max: 10,
    step: 1,
    decimals: 0,
  };
  static readonly DISPLAY_DECIMALS: boolean = false; // hiding decimals in numeric sliders for now and setting based on min/max values
  static readonly DISPLAY_STEP: boolean = true; // only showing steps for the slider for now

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionTypeNumericSlider.DEFAULT_ORDINALITIES) as TQuestionTypeNumericSliderOrdinalities,
    i18n: NodeTranslations = {}
  ) {

    super(baseNodeParams, ordinalities, i18n);

    this.type = NodeTypeId.NumericSlider;
  }

  validateOrdinalities(survey: Survey) {
    super.validateOrdinalities(survey);

    if (!this.hasMin() || !this.hasMax() || !this.hasStep()) {
      // min/max/step are required for sliders
      if (!this.hasMin()) {
        survey.errors.push(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.NodeOrdinalitiesNumericMin, this.ref));
      }
      if (!this.hasMax()) {
        survey.errors.push(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.NodeOrdinalitiesNumericMax, this.ref));
      }
      if (!this.hasStep()) {
        survey.errors.push(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.NodeOrdinalitiesNumericStep, this.ref));
      }
    } else {
      // must have equal intervals in the range
      if (!this.rangeHasEqualIntervals()) {
        survey.errors.push(new SurveyError(SurveyErrorType.Invalid, SurveyErrorProperty.NodeOrdinalitiesNumericSliderIntervals, this.ref));
      }

      // steps must be less than the max
      if (this.ordinalities.step >= this.ordinalities.max) {
        survey.errors.push(new SurveyError(SurveyErrorType.Invalid, SurveyErrorProperty.NodeOrdinalitiesNumericStep, this.ref));
      }
    }

    // hardcode decimals for now based on whether decimals exist in any fields
    if (isFloat(this.ordinalities.step) || isFloat(this.ordinalities.min) || isFloat(this.ordinalities.max)) {
      this.ordinalities.decimals = QuestionTypeNumeric.DEFAULT_ORDINALITIES_DECIMALS;
    } else {
      this.ordinalities.decimals = QuestionTypeNumeric.DEFAULT_ORDINALITIES_NO_DECIMALS;
    }
  }

  rangeHasEqualIntervals() {
    const { min, max, step } = this.ordinalities;
    let isRangeValid = false

    if (Number.isInteger(step)) {
      isRangeValid = !((max - min) % step)
    } else {
      const floatModule = ((((max - min) * 100) % (step * 100)) / 100)
      isRangeValid = !floatModule
    }
    if (!Number.isInteger(min) || !Number.isInteger(max)) {
      isRangeValid = false
    }

    return isRangeValid;
  }
}

export interface QuestionTypeNumericSliderOrdinalities extends QuestionTypeNumericOrdinalities {}
