export default {
  fileDialog: {
    uploadLabel: 'Click or drop files to upload',
    dropLabel: 'Drop your file here',
    a11y: 'Accessibility',
    source: 'Source',
    uploadCaptions: 'Upload Captions',
    altLabel: 'Alternative description (will not be shown to users)',
    width: 'Width in pixels',
    height: 'Height in pixels',
    widthError: 'Enter a valid number in pixels',
    heightError: 'Enter a valid number in pixels',
    aspectRatio: 'Aspect Ratio',
    insertEditMedia: 'Insert/Edit Video/Audio',
    insertEditImage: 'Insert/Edit Image',
    image: 'Image',
    media: 'Video/Audio',
    supportedImages: 'Supported image types are PNG, JPG/JPEG, GIF & SVG. Max file size is 16MB.',
    supportedMedia: 'Supported file types are MP4, WAV, MP3, M4A. Max file size is 120MB.',
    supportedCaptions: 'Supported file type is VTT. Max file size is 100KB.',
    fileSizeError: 'File size should be less than 120MB!',
    imageSizeError: 'File size must be less than 16MB!',
    captionSizeError: 'File size must be less than 100KB!',
    fileError: 'Upload a file',
    formError: 'Please fill out all required fields with valid values'
  },
}
