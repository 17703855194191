import Option from "./Option";
import {uuid} from "@/utils/string";
import QuestionType1d, {
  OptionSetProperty,
  QuestionOptionConfigurationsProps
} from "@/models/nodeTypes/QuestionType1d";
import ValidationException from "@/exceptions/ValidationException";
import {cloneDeep} from "@/utils/lodash";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import Survey from "@/models/Survey";
import Asset from "./Asset";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";

export enum OptionSetOrder {
  Normal = 'normal',
  Shuffle = 'shuffle'
}

export default class OptionSet {
  static readonly DEFAULT_ORDER = OptionSetOrder.Normal;
  static readonly MAX_NUM_OPTIONS = 200 //TODO: increase after performance testing

  options: Option[];

  constructor(
    public ref?: string,
    public order: OptionSetOrder = OptionSet.DEFAULT_ORDER,
    options: Array<Option> = [],
  ) {

    this.ref = this.ref ?? uuid();
    this.order = this.order ?? OptionSet.DEFAULT_ORDER;

    this.options = [];
    try {
      this.addOptions(options ?? []);
    } catch (err) {
      //TODO: catch error, append option set data and throw
    }
  }

  validate(survey: Survey, node: QuestionType1d, codeChangeMap: Map<number, number> = null, validateText: boolean = true, validateIncomplete: boolean = true) {
    if (validateIncomplete) {
      // don't want to validate length immediately for a new question
      this.validateOptionsLength(survey, node);
    }
    this.validateOptions(survey, node, codeChangeMap, validateText, validateIncomplete);
  }

  validateOptions(survey: Survey, node: QuestionType1d, codeChangeMap: Map<number, number> = null, validateText = true, validateIncomplete: boolean = true) {
    this.options.forEach(option => {
      option.validate(node, this, survey, codeChangeMap, validateText, validateIncomplete);
    })
  }

  validateOptionsLength(survey: Survey, node: QuestionType1d) {
    survey.resetSurveyErrors(this.ref, null, [SurveyErrorProperty.OptionSetOptions], [SurveyErrorType.Empty]);
    if (!this.options.length || (this.options.length === 1 && this.isPlaceholderOption(this.options[0]))) {
      survey.addSurveyError(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.OptionSetOptions, this.ref, node.ref))
    }
  }

  getMaxOptionCode(): number {
    if (Array.isArray(this.options) && this.options.length > 0) {
      const maxCode = (this.options as Option[]).reduce((acc: number, curr: Option) => {
        return Math.max(acc, curr.code)
      }, 0);
      return maxCode + 1;
    } else {
      return 1;
    }
  }

  addOption(option: Option, optionIndex: number | null = null, question: QuestionType1d = null,
            optionConfiguration: QuestionOptionConfigurationsProps = QuestionType1d.DEFAULT_OPTION_CONFIGURATION,
            survey: Survey = null) {
    if (optionIndex === null) {
      optionIndex = this.options.length;
    }
    this.options.splice(optionIndex, 0, option);

    if (question instanceof QuestionType1d) {
      question.setQuestionOptionConfiguration(option.ref, cloneDeep(optionConfiguration));

      if (question.set1 === this.ref && survey) {
        // only supporting set1 options for question quota groups and masking for now
        survey.addOptionQuotaToQuestionQuotaGroup(option, question, optionIndex);
        question.updateMaskedChildOption(option, survey, false, 'set1', false);
      }
    }
  }

  moveOption(survey: Survey, currentIndex: number, newIndex: number) {
    if (this.options[currentIndex] && this.options[newIndex]) {
      this.options.splice(newIndex, 0, this.options.splice(currentIndex, 1)[0]);
    }
  }

  addPlaceholderOption(question: QuestionType1d = null) {
    // placeholder options need to be added as a real option, else if done with just CSS, UI is glitchy / text moves while user is typing
    if (!this.getPlaceholderOption()) {
      const code = this.getMaxOptionCode();
      const option = new Option(code);
      this.addOption(option, null, question);
    }
  }

  addOptions(options: Option[]) {
    let hasSpecified = false;
    options.forEach(option => {
      let specify = false;
      if (option.specify && !hasSpecified) {
        specify = true;
        hasSpecified = true;
      }
      (this.options as Option[]).push(
        new Option(
          option.code,
          option.ref ?? null,
          option.label ?? null,
          option.pin ?? null,
          option.exclusive ?? null,
          specify,
          option.i18n ?? null,
          option.asset ?? null,
        )
      )
    })
  }

  deleteOptions(survey: Survey, question: QuestionType1d | QuestionType2d, specificOptions: Option[] = null, optionSetProperty: OptionSetProperty = 'set1') {
    const options = specificOptions ?? this.options;

    for (let i = options.length - 1; i >= 0; i--) {
      const index = specificOptions ? this.getOptionIndexByRef(options[i].ref) : i;
      this.deleteOptionByIndex(survey, index, question, optionSetProperty, false);
    }

    survey.validateLogic(true);
  }

  deleteOptionByIndex(survey: Survey, optionIndex: number, question: QuestionType1d, optionSetProperty: OptionSetProperty = 'set1', validateLogic = true) {
    const option: Option = this.options[optionIndex] ?? null;
    if (option) {
      const optionSet = survey.getOptionSetByRef(question[optionSetProperty]);
      const isPlaceholder = optionSet?.getPlaceholderOption() === option;
      if (!isPlaceholder) {
        question.updateMaskedChildOption(option, survey, true, optionSetProperty, false);
        survey.deleteOptionQuotaInQuestionQuotaGroup(option, question);
        optionSet.validateOptionsLength(survey, question);
      }

      question.deleteQuestionOptionConfiguration(option.ref);
      this.options.splice(optionIndex, 1);

      survey.resetSurveyErrors(option.ref);

      if (validateLogic) {
        survey.validateLogic(true);
      }
    }
  }

  handleOptionChange(option: Option = null, node: QuestionType1d, survey: Survey, isMoved: boolean = false, updateDependants: boolean = false) {
    if (updateDependants) {
      survey.updateOptionQuotaInQuestionQuotaGroup(option, node);
      node.updateMaskedChildOption(option, survey, false, this.getNodeOptionSetProperty(node));
    }

    if (isMoved) {
      node.updateMaskedChildOptionPositions(survey, 'set1');
      survey.updateQuestionQuotaGroupQuotaPositions(node);
    } else {
      // just validate the text
      option.validateText(node, this, survey);
    }
  }

  setExclusiveByIndex(optionIndex: number) {
    const option: Option = this.options[optionIndex] ?? null;
    if (option) {
      option.exclusive = !option.exclusive
    }
  }

  setSpecifyByIndex(optionIndex: number) {
    const option: Option = this.options[optionIndex] ?? null;
    if (option) {
      this.disableOtherSpecifiesByIndex(optionIndex)
      option.specify = !option.specify
    }
  }

  // For now Questions only supports a single open end option per set. Until then we must ensure only one is selected in SB.
  disableOtherSpecifiesByIndex(optionIndex: number) {
    this.options.forEach((option, i) => {
      if (i !== optionIndex) {
        option.specify = false;
      }
    });
  }

  setPinByIndex(optionIndex: number) {
    const option: Option = this.options[optionIndex] ?? null;
    if (option) {
      option.pin = !option.pin
    }
  }

  getOptionByRef(optionRef: string): Option | null {
    return this.options.find(option => option.ref === optionRef) ?? null;
  }

  getOptionIndexByRef(optionRef: string): number {
    return this.options.findIndex(option => option.ref === optionRef);
  }

  getNodeOptionSetProperty(node: QuestionType1d): OptionSetProperty|null {
    return node['set1'] === this.ref
      ? 'set1'
      : node['set2'] === this.ref
        ? 'set2'
        : null
  }

  getPlaceholderOption(): Option | null {
    const lastOption = this.options[this.options.length - 1];
    if (lastOption && (lastOption.label === null || lastOption.label === "") && lastOption.asset === null) {
      return lastOption;
    }
    return null;
  }

  isPlaceholderOption(option: Option): boolean {
    return option.ref === this.getPlaceholderOption()?.ref;
  }

  deletePlaceholderOption(survey: Survey, node: QuestionType1d|QuestionType2d) {
    const placeholder = this.getPlaceholderOption();
    if (placeholder) {
      this.deleteOptionByIndex(survey, this.options.length - 1, node);
    }
  }

  getOptionsLength(excludePlaceholder = true): number {
    return this.options.length - (excludePlaceholder && this.getPlaceholderOption() ? 1 : 0);
  }

  getAssets(): Array<Asset> {
    return this.options.map((option: Option) => option.asset).filter((asset?: Asset) => asset !== null)
  }
}
