export const splitBreaklineString = (value: string): string[] => {
  if (!value) {
    return []
  }

  return value.split(/\r?\n/)
}

export const uuid = (): string => {
  if (typeof self !== 'undefined') {
    return self.crypto.randomUUID();
  }

  return Array.from({ length: 35 }, () => Math.floor(Math.random() * 10)).join('');
}

export const stripHTML = (value: string, replaceLineBreaks = false): string => {
  const string = new DOMParser().parseFromString(value, 'text/html').body.textContent || '';
  return replaceLineBreaks ? string.replaceAll(/\r?\n/g, '') : string;
}

export const replaceSpaceCode = (value: string): string => {
  return value.replaceAll(/&nbsp;/g, ' ')
}

export const convertStringToNumber = (value: string | number | null | undefined): number | null | undefined => {
  if (value === null || value === undefined) {
    return value as null | undefined;
  }

  const parsedValue = parseFloat(String(value));

  return !isNumber(parsedValue) ? null : parsedValue;
}

export const isNumber = (value: any): boolean => {
  return typeof value === 'number' && !isNaN(value);
}

export const isFloat = (value): boolean => {
  return isNumber(value) && !Number.isInteger(value);
}

export const convertKebabCaseToCamelCase = (value: string): string => {
  return value.replace(/-([a-z])/g, (hyphenLetter) => {
    return hyphenLetter[1].toUpperCase();
  })
}

export const increaseNumberOnDuplicatedTitle = (name: string): string => {
  const regexPattern = /(\ \d+$)/g;
  const match = name.match(regexPattern)
  if (match) {
    const sumValue = parseInt(match[0]) + 1
    return name.replace(regexPattern, ` ${sumValue}`)
  }

  return name + ' 1'
}
