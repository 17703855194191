import Locale from './Locale'
import Survey from "@/models/Survey";

export type TranslationRecord = {
  context: string,
  id: string,
  'original-text': string,
  'translated-text': string,
}

export type TranslationsArray = Array<TranslationRecord & Array<string>>;

export default class TranslationData {
  private data: Array<TranslationRecord>;
  private trgLocale: Locale;
  private srcLocale: Locale;

  constructor(data: Array<TranslationRecord>, targetLocale: Locale, sourceLocale: Locale = Locale.default) {
    this.data = data;
    this.trgLocale = targetLocale;
    this.srcLocale = sourceLocale;
  }

  get targetLocale() {
    return this.trgLocale;
  }

  get sourceLocale() {
    return this.srcLocale;
  }

  get translations() {
    return this.data.filter(obj => obj['translated-text']?.trim().length > 0)
  }

  get total() {
    return this.data.length;
  }

  get translated() {
    return this.translations.length;
  }

  get headers(): Array<string> {
    return Object.keys(this.data[0])
  }

  get array(): TranslationsArray {
    return this.data.map(obj => Object.assign([], {...obj, ...Object.values(obj)}));
  }

  translationsFor(survey: Survey): Array<TranslationRecord> {
    return this.translations.filter(
      (translation: TranslationRecord) => survey.getNodeByRef(translation.id) || survey.getOptionByRef(translation.id)
    )
  }

  [Symbol.iterator]() {
    return this.data;
  }
}
