import CustomError from "@/utils/customError";
import {Locale} from "@/models/translation/index";

export class TranslationError extends CustomError {
  constructor(public locale: Locale) {
    super();
  }

  get translateOptions() {
    return {
      language: this.locale.displayName(),
    };
  }
}

export class UnknownTargetLocaleError extends CustomError {
  constructor(public locale: string) {
    super();
  }

  get translateOptions() {
    return {
      locale: this.locale,
    }
  }
}

export class UnsupportedFileError extends CustomError {}
export class CannotDeleteDefaultLocaleTranslationsError extends TranslationError {}
export class UnsupportedLanguageError extends TranslationError {}
