import { useShellStore } from '@maru/shell-app';
import router from '@/router';

let listener = null;
const onClickHandler = function (event){
  event.preventDefault();
  if (event.metaKey) {
    window.open(this.fullPath, '_blank');
  } else {
    router.push(this.binding.value);
  }
};

const initPath = (el, binding) => {
  const shellStore = useShellStore();
  const parentHref = shellStore.location.href;
  let fullPath = `${ parentHref }`;

  if (binding.modifiers.root) {
    fullPath += '/==';
  }
  if (binding.value.startsWith('/')) { // absolute path
    const absolutePathIndex = fullPath.lastIndexOf('/==');
    fullPath = fullPath.substring(0, absolutePathIndex + 3); // need to truncate the value
    fullPath += binding.value;
  } else { // relative path
    fullPath += '/' + binding.value; // just append the new path
  }
  el.href = fullPath;
  listener = onClickHandler.bind({ binding, fullPath });
  el.onclick = listener;
};

export const redirect = {
  mounted: initPath,
  beforeUpdate: initPath,
  beforeUnmount(el) {
    el.removeEventListener('click', listener);
  }
};
