export default {
  mediaUploader: {
    label: 'Click or drop file to upload',
    placeholder: 'Upload a file from your device',
    hint: 'Supported file types are PNG, JPG/JPEG, GIF, SVG, MP4, AVI, MOV. Max file size is 100MB',
    fileSizeLimitErrorMessage: 'File size should be less than 100 MB!',
    toggleButtonLabel: 'Include images or media',
    gotError: 'Got an error',
    files: 'File(s)',
    vttNotice: "You have uploaded a video file. If necessary, upload a capture textes in .vtt format.",
    uploadMediaError: "Something went wrong with uploading media"
  }
}