import QuestionType1d, {
  LayoutAnswerColumns,
  OptionSetParams,
  QuestionType1dOrdinalities,
} from "./QuestionType1d";
import {BaseNodeParams, NodeOrdinalitiesTypes, NodeTranslations, NodeTypeId} from "@/models/Node";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeSingleSelect<
  TQuestionTypeSingleSelectOrdinalities extends QuestionTypeSingleSelectOrdinalities = QuestionTypeSingleSelectOrdinalities
> extends QuestionType1d<TQuestionTypeSingleSelectOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-radiobox-marked';
  static readonly NODE_TITLE: string = 'question.type.singleSelect';
  static readonly DEFAULT_ORDINALITIES: QuestionTypeSingleSelectOrdinalities = { layoutAnswerColumns: LayoutAnswerColumns.One };
  static readonly ORDINALITY_TYPES: NodeOrdinalitiesTypes = { layoutAnswerColumns: [LayoutAnswerColumns, 'null'] };
  static readonly NODE_SET1_SUPPORTS_SPECIFY: boolean = true;
  static readonly NODE_SET1_SUPPORTS_EXCLUSIVE: boolean = false;

  constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionTypeSingleSelect.DEFAULT_ORDINALITIES) as TQuestionTypeSingleSelectOrdinalities,
    i18n: NodeTranslations = {},
    optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.SingleSelect;
  }
}

export interface QuestionTypeSingleSelectOrdinalities extends QuestionType1dOrdinalities {}
